import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AppRouter from "./Router";
import store from "./store";
import ErrorBoundary from "./components/ErrorBoundary";

import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider      

        theme={{
          select: {
            style: {
              borderColor: 'red', // Set border color to red globally for Select component
            },
          },
          token: {
            // colorBgBase: "#FBF8FF",
            // colorPrimary: '#F9F5FF',
            // textColor: '#6941C6'
          },
          
        }}
      >
        <ErrorBoundary>
          <Router>
            <Routes>
              <Route  path="/ui/*" element={ <AppRouter />}/>
            </Routes>
          </Router>
         
        </ErrorBoundary>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
