import { createSlice, combineReducers } from "@reduxjs/toolkit";
import { createFetchReducer } from "../../utils/reduxUtility";
import GENERIC_CONSTANTS from "../../Constants/generic";

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
  dataSummaryTabKey:'2',
  topFilterPayload:{},
  hrbpToggler:false,
  isTimeLineChanged:false,
  isReporteeChanged:false,
  isSpanChanged:false,
  actionTableKey:GENERIC_CONSTANTS.ACTION_TABS.OPEN,
  isActionTableKeyChanged:false,
  isRemarksAdded:false
};

const topFilterSlice = createSlice({
  name: "topFilter",
  initialState,
  reducers: {
    ...createFetchReducer("getTopFilter"),
    ...createFetchReducer("getManagerReporteeList"),
    ...createFetchReducer("getHrbpReporteeList"),
    resetMetricsSummary: (state) => {
      return initialState;
    },

    setDataSummaryTabKey:(state,action)=>{
      state.dataSummaryTabKey = action.payload;
    },
    setHrbpToggler:(state,action)=>{
      state.hrbpToggler = action.payload
    },
    updateTopFilterPayLoad:(state,action)=>{
      state.topFilterPayload = { ...state.topFilterPayload, ...action.payload };
    },
    setIsTimeLineChanged :(state,action)=>{
      return{...state,isTimeLineChanged:action.payload}
    },
    setIsReporteeChanged :(state,action)=>{
      return{...state,isReporteeChanged:action.payload}
    },
    setIsSpanChanged :(state,action)=>{
      return {...state,isSpanChanged:action.payload}
    },
    setActionTableKey :(state,action)=>{
      return {...state,actionTableKey:action.payload}
    },
    setActionTableKeyIsChanged:(state,action)=>{
      return {...state,isActionTableKeyChanged:action.payload}
    },
    setIsRemarksAdded:(state,action)=>{
      return {...state,isRemarksAdded:action.payload}
    }
  },
});

export const {
  getTopFilter,
  getTopFilterSuccess,
  getTopFilterFailure,

  getManagerReporteeList,
  getManagerReporteeListSuccess,
  getManagerReporteeListFailure,
  
  getHrbpReporteeList,
  getHrbpReporteeListSuccess,
  getHrbpReporteeListFailure,
  
  setIsTimeLineChanged,
  setIsReporteeChanged,
  setIsSpanChanged,

  setDataSummaryTabKey,
  setHrbpToggler,
  updateTopFilterPayLoad,

  setActionTableKey,
  setActionTableKeyIsChanged,
  setIsRemarksAdded,

  resetTopFilter,
} = topFilterSlice.actions;

export default combineReducers({
  topFilter: topFilterSlice.reducer,
});
