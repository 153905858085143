import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getHealthSummary = () => {
	return axiosInstance.get(apiPaths.getHealthSummary);
};
const getReporteeHealthSummary = (payload) => {
	return axiosInstance.post(apiPaths.getReporteeHealthSummary,payload);
};

export default {
	getHealthSummary,
	getReporteeHealthSummary,
};

