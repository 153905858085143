import React, { useEffect, useState } from "react";
import { UpOutlined, DownOutlined,  } from "@ant-design/icons";
import "./ActionComponent.css";
import { Form, Select, DatePicker, Button, Input , Tooltip, Cascader, TreeSelect} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addAction,
  editAction,
  updateEditFlag,
} from "../../redux/actions/actionsSlice";
import {getActionPlansList} from '../../redux/actionPlans/actionPlansSlice'
import GENERIC_CONSTANTS from "../../Constants/generic";
import { reporteesOptionListCreator, validateAction } from "../../utils/helpers";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { IMAGES } from "../../utils/images";


  dayjs.extend(customParseFormat);
  const { Option } = Select;
  const ActionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [createYourOwn, setCreateYourOwn] = useState(false);
  const [actionPlanId,setActionPlanId] = useState(0);
  const editFlag = useSelector((state) => state.actions.actions.editFlag);
  const editId = useSelector((state) => state.actions.actions.editId);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [cascaderOpen,setCascaderOpen]  = useState(false);
  const [actionValid,setActionValid] = useState("");
  const actionData = useSelector(
    (state) => state.actions.actions.data.data
  );
  const actionPlansData = useSelector(
    (state) => state.actionPlans.actionPlans.data?.actionItems
  ) ||[];
  const dataToEdit = actionData?.filter((item) => item?.Col1?.actionId === editId)[0];
  const { reporteeList } = useSelector(
    (state) => state.topFilter.topFilter.data
  );
 
  const { topFilterPayload } = useSelector(state => state.topFilter.topFilter);
  const handleInputKeyDown = (event) => {
    const { key } = event;
    if (/[^a-zA-Z]/.test(key)) {
      event.preventDefault();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const cascaderOptions = [
    {
      value: GENERIC_CONSTANTS.ACTION_CONTROL.CREATE,
      label:<div style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>Write your own action</div>
        <div>      <div className="send-icon-style"
          style={{ border: "none !important " }} >
          {<img src={IMAGES.ICONS.PENCIL} 
          alt="PENCIL" />}
        </div></div>

      </div>
    },
    ...actionPlansData
  ];

    const disabledPastDate = current => {
      // Disable dates before today
      return current && current < dayjs().startOf('day');
    };

    const dropdownStyle = {
      padding: "8px", // Padding for the dropdown menu
      borderRadius: "12px",
      border: "1px solid #D0D5DD", // Border radius for the dropdown menu
      boxShadow: "0px 4px 6px -2px #10182808",
      boxShadow: "0px 12px 16px -4px #10182814",
      color: "#344054",
      fontWeight: "bolder",
    };

    const dateCellRender = React.useCallback((current, info) => {
      const style = 
      {
        height:'30px',
        width:'30px',
        display:'grid',
        placeContent:'center'
      };
  
      if (info.type !== 'date') {
        return info.originNode;
      }
  
      if (typeof current === 'number') {
        return <div className="ant-picker-cell-inner">{current}</div>;
      }
      
      const isSelected = selectedDate && current.isSame(selectedDate, 'day');
      const isToday = current.isSame(new Date(), 'day');
      return (
        <div
        className={`ant-picker-cell-inner ${isToday ? '' : ''}`}
          style={{
            ...style,
            backgroundColor :'inherit',
            border:'inherit',
            borderRadius:"50%"
          }}
          onClick={() => setSelectedDate(current)}
        >
          {current.date()}
        </div>
      );
    }, [selectedDate]);


  useEffect(()=>{
      dispatch(getActionPlansList({}));
  },[dispatch]);
  useEffect(() => {
    setIsOpen(editFlag);
  }, [editFlag]);
  useEffect(() => {
    if (dataToEdit) {
      // Set form field values when dataToEdit is available
      form.setFieldsValue({
        assignedToEmail: dataToEdit?.Col2?.assignedToName,
        actionSelect: dataToEdit?.Col1?.text,
        actionDesc: dataToEdit?.Col1?.text,
        dueDate: dayjs(dataToEdit?.Col1.dueBy, GENERIC_CONSTANTS.DATE.FORMAT),
      });
      setSelectedDate(dayjs(dataToEdit?.Col1.dueBy, GENERIC_CONSTANTS.DATE.FORMAT))
      setCreateYourOwn(true);
    }
  }, [dataToEdit]);

  const toggleChat = () => {
    if (isOpen) dispatch(updateEditFlag(false));
    setCreateYourOwn(false);
    form.resetFields();
    setIsOpen(!isOpen);
    setSelectedDate(null);
  };

  const handleDiscard = () => {
    toggleChat();
    setCreateYourOwn(false);
    setActionValid(GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.SUCCESS)
  }

  const displayRender = (labels, selectedOptions) => {
    if(selectedOptions?.[0]?.value === 'Create'){
      return 'Write your own'
    }
    else{
        return labels?.[labels?.length-1];
    }
  };

  const onFinish = (values) => {
    const {span,...payloadWithoutSpan} = topFilterPayload;
    let actionData = { ...values ,actionPlanId:dataToEdit?.Col1.actionPlanId || null} ;
    delete actionData.actionSelect;
    actionData.dueDate = values?.dueDate?.format(
      GENERIC_CONSTANTS.DATE.FORMAT
    );
    if (!createYourOwn) {
      actionData.actionDesc = Array.isArray(values.actionSelect)?values.actionSelect[values.actionSelect.length - 1]:values.actionSelect;
      actionData.actionPlanId =  actionPlanId;
    }
    else{
        actionData.actionPlanId = null; 
    }
    if(!validateAction(actionData.actionDesc)) {
      setActionValid(GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.ERROR);
      return;
    }

    if(editId){
      actionData.actionId = editId;
      delete actionData.assignedToEmail;
    }
    else{
      actionData.assignedToEmail = selectedAssignee;
    }
    !editFlag
      ? dispatch(addAction({ actionData, ...payloadWithoutSpan, status: GENERIC_CONSTANTS.ACTION_TABS.OPEN }))
      : dispatch(editAction({ actionData, ...payloadWithoutSpan, status: GENERIC_CONSTANTS.ACTION_TABS.OPEN }));
    dispatch(updateEditFlag(false));
    form.resetFields();
    setCreateYourOwn(false);
    setSelectedDate(null);
    handleDiscard();
  };

  const handleChangeAction = (value,selectedOptions) => {
    setActionPlanId(selectedOptions?.[selectedOptions?.length-1]?.actionPlanId)
    console.log(actionPlanId)
    console.log(selectedOptions);
    value?.[0] === GENERIC_CONSTANTS.ACTION_CONTROL.CREATE
      ? setCreateYourOwn(true)
      : setCreateYourOwn(false);
  };

  const handleChangeMember = (value,selectedOptions) => {
      setSelectedAssignee(selectedOptions?.reporteeEmail);
      form.setFieldsValue({
        assignedToEmail: selectedOptions?.reporteeName
    });
  };
  useEffect(()=>{
    setSelectedAssignee(topFilterPayload?.reportee?.reporteeEmail);
    form.setFieldsValue({
      assignedToEmail: topFilterPayload?.reportee?.reporteeName
  });
  },[topFilterPayload?.reportee?.reporteeName,isOpen])
  return (
    <div className={`action-component-container ${isOpen ? "open" : ""}`} style={{height:cascaderOpen?'97vh':'auto'}}>
      <div className="action-component-header" onClick={toggleChat}>
        <span className="action-component-title">
          {editFlag
            ? GENERIC_CONSTANTS.ACTION_CONTROL.TITLE.EDIT
            : GENERIC_CONSTANTS.ACTION_CONTROL.TITLE.ADD}
        </span>
        {isOpen ? (
          <DownOutlined className="action-component-arrow" />
        ) : (
          <UpOutlined  className="action-component-arrow" />
        )}
      </div>
      {isOpen && (
        <div className="action-component-content">
          <div className="chat-content">
            <Form
              form={form}
              className="action-component-form"
              layout="vertical"
              onFinish={onFinish}
              // onValuesChange={onValuesChange}
            >
              <Form.Item
                name="assignedToEmail"
                label="Assign for"
                className="action-component-form"
                rules={[
                  {
                    required: true,
                    message: "Please select a member",
                  },
                ]}
              >
            <TreeSelect
                  showSearch
                  placeholder="Select members"
                  className="select-member-dropdown"
                  virtual = {false}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  treeData = {reporteesOptionListCreator(reporteeList)}
                  allowClear
                  onInputKeyDown={handleInputKeyDown}
                  onSelect={handleChangeMember}
                  disabled = {true}
            />
                 
              </Form.Item>
              <Form.Item
                name="actionSelect"
                label="Identify Actions"
                placeholder="Select Identify actions"
                rules={[
                  {
                    required: true,
                    message: "Please select an action",
                  },
                ]}
              >
                <Cascader  
                  placeholder="Select action" 
                  options={cascaderOptions} 
                  onChange={handleChangeAction}
                  displayRender={displayRender}
                  onDropdownVisibleChange={()=>setCascaderOpen(!cascaderOpen)}
                  
                />
                  
              </Form.Item>

              {createYourOwn && (
                <div>
                <Form.Item
                  name="actionDesc"
                  placeholder="I should probably do ...."
                  validateStatus={actionValid}
                  help={actionValid === GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.ERROR ? `${GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.ACTION_DESCRIPTION}`:''}
                >
                  <Input.TextArea  onChange={(e)=>{
                    if(validateAction(e.target.value)){
                      setActionValid(GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.SUCCESS);
                    }
                  }} className="write-your-own" placeholder="I should probably do ...." />
                </Form.Item>
                <p className="text">In case you don’t find it in dropdown</p>
                </div>
              )}
              <Form.Item
                name="dueDate"
                label="Due By"
                placeholder="Due by"
                className="action-component-form"
                rules={[
                  {
                    required: true,
                    message: "Please select a due date",
                  },
                ]}
              >
                <DatePicker 
                  style={{ width: "100%" }} 
                  cellRender={dateCellRender} 
                  onChange={handleDateChange} 
                  disabledDate={disabledPastDate}
                  onClick={()=>
                    { 
                      if(!editFlag && !selectedDate){
                        form.setFieldsValue({dueDate: dayjs()})}
                      } 
                   }
                />
              </Form.Item>

              <Form.Item
                style={{ textAlign: "right" }}
                className="action-component-form"
              >
                <Button className="discard-btn" type="Default" htmlType="submit"
                onClick={handleDiscard}
                >Cancel</Button>
                <Button className="save-btn" htmlType="submit">{editFlag ? "Save Action" : "Add Action"}</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionComponent;

