import React, { useEffect ,useRef} from "react";
import * as echarts from "echarts";
import { DataNotAvailable } from "../../DataNotAvailable";
import { ErrorComponent } from "../../ErrorComponent";
import { checkDataAvailability, resizeChart } from "../../../utils/helpers";
import { Loader } from "../../Loader";
import {
  chartTooltip,
  chartLegends,
  chartXaxis,
  chartYaxis,
  chartGrid,
  chartSeriesData,
} from "../../../utils/helpers";
import "./styles/chartStyle.css";
import { useGetData } from "../../../utils/hooks/useGetData";
import GENERIC_CONSTANTS from '../../../Constants/generic';

function AttritionChartByGender() {
  
  const { data, error, isLoading } = useGetData(GENERIC_CONSTANTS.REDUCER_NAMES.ATTRITION_DEEPDIVE);
  const {gender:chartData} = data;
  const chartRef = useRef(null);

  useEffect(() => {
    // Initialize ECharts
    let chart;
    if (chartRef.current !== null ) {
      chart = echarts.init(chartRef.current);
    }
    const resizeHandler = () => chart?.resize();
    window.addEventListener("resize", resizeHandler);
    const options = {
      grid: chartGrid,
      legend:{...chartLegends,data: chartData?.legend?.data},
      tooltip: chartTooltip,
      xAxis: {...chartXaxis,type: chartData?.xaxis?.type || "category",data: chartData?.xaxis?.data || [],},
      yAxis: {...chartYaxis,type: chartData?.yaxis?.type || "value", data: chartData?.yaxis?.data || [],axisLabel:{...chartYaxis.axisLabel,formatter: "{value}%",}},
      series : chartSeriesData (chartData,["#05C3DE","#026F85","#FFA3B5","#A6586B"],[[-33,-30],[10,10]]),
    };
    // Set the chart options
    checkDataAvailability(chartData) ? chart.setOption(options) : chart.clear();
    return () => {
      window.removeEventListener("resize",resizeHandler);
      chart?.dispose();
    };
  }, [chartData]);

  useEffect(() => {
    const resizeObserver = resizeChart(chartRef,echarts);
    return () => {
      resizeObserver.disconnect();
    };
  }, []); 

  return (
    <>
      {isLoading && <Loader />}
      {error && !isLoading && <ErrorComponent error={error} />}
      {!checkDataAvailability(chartData) && !error && !isLoading && (
        <DataNotAvailable />
      )}

      <div
        className="chart_container"
        style={{
          opacity: isLoading ? 0.5 : 1,
          transition: "opacity 0.5s ease",
        }}
        ref={chartRef}
      ></div>
    </>
  );
}

export default AttritionChartByGender;