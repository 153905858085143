import React, { useEffect ,useRef} from 'react';
import * as echarts from 'echarts';
import "./styles/chartStyle.css";
import {
  chartTooltip,
  checkDataAvailability,
  chartLegends,
  chartXaxis,
  chartYaxis,
  chartGrid,
  chartSeriesData,
  resizeChart,
} from "../../../utils/helpers";
import { DataNotAvailable } from '../../DataNotAvailable';
import { ErrorComponent } from '../../ErrorComponent';
import { Loader } from '../../Loader';
import { useGetData } from '../../../utils/hooks/useGetData';
import GENERIC_CONSTANTS from '../../../Constants/generic';

const GenderDiversity = () => {

  const { data, error, isLoading } = useGetData(GENERIC_CONSTANTS.REDUCER_NAMES.STRUCTURE_DEEPDIVE);
  const { gender:chartData } = data;
  const chartRef = useRef(null);
  
  useEffect(() => {
    let chart;
    if (chartRef.current !== null ) {
      chart = echarts.init(chartRef.current);
    }
    const resizeHandler = () => chart?.resize();
    window.addEventListener("resize", resizeHandler);
    var options = {
      grid : {...chartGrid,left:"3.4%",right: "4%",},
      xAxis : {...chartXaxis,type: chartData?.xaxis?.type || "category",data: chartData?.xaxis?.data || [],},
      yAxis : [{...chartYaxis,type: chartData?.yaxis?.type || "value", data: chartData?.yaxis?.data || [],splitLine: {show: true},alignTicks: true,},
      {...chartYaxis,type: chartData?.yaxis?.type || "value", data: chartData?.yaxis?.data || [],splitLine: {show: true},alignTicks: true,axisLabel:{...chartYaxis.axisLabel,formatter: "{value}%"}},],
      legend : {...chartLegends,data: chartData?.legend?.data,bottom: -2,left: 12,},
      series : chartSeriesData (chartData,["#05C3DE","#FFA3B5","#FFA3B5"],["insideTop","top",[15,0]],'ad',null,1),
      tooltip : chartTooltip,
    };
    checkDataAvailability(chartData) ? chart.setOption(options) : chart.clear();    
    return () => {
      window.removeEventListener("resize", resizeHandler);
      chart?.dispose();
    };
  }, [chartData]); 

  useEffect(() => {
    const resizeObserver = resizeChart(chartRef,echarts);
    return () => {
      resizeObserver.disconnect();
    };
  }, []); 

  return (
    <>
      {isLoading && <Loader />}
      {error && !isLoading && <ErrorComponent error={error} />}
      {!checkDataAvailability(chartData) && !error && !isLoading && (
        <DataNotAvailable />
      )}
      <div className="chartContent" ref={chartRef}></div>
    </>
  );
};

export default GenderDiversity;