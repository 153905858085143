import React from "react";
import { IMAGES } from "../utils/images";
import GENERIC_CONSTANTS from "../Constants/generic";

const TrendIndicator = ({ percentage, trend ,tab}) => {

  const style = {
    width: "76px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    borderRadius: "8px"
  };
  let iconSrc;
  let altText;
  if ( tab === GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TAB_NAME && trend === GENERIC_CONSTANTS.TREND.UP) {
    style.backgroundColor = GENERIC_CONSTANTS.COLORS.LIGHT_GREEN;
    style.color = GENERIC_CONSTANTS.COLORS.DARK_GREEN;
    iconSrc = IMAGES.ICONS.TREND_UP;
    altText = IMAGES.ALT_TEXT.TREND_UP;
  } else if ( tab === GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TAB_NAME  && trend ===  GENERIC_CONSTANTS.TREND.DOWN) {
    style.backgroundColor = GENERIC_CONSTANTS.COLORS.LIGHT_RED;
    style.color = GENERIC_CONSTANTS.COLORS.DARK_RED;
    iconSrc = IMAGES.ICONS.TREND_DOWN;
    altText = IMAGES.ALT_TEXT.TREND_DOWN; 
  } else if (tab === GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TAB_NAME ){
    style.backgroundColor = GENERIC_CONSTANTS.COLORS.DARK_GREY;
    style.color = GENERIC_CONSTANTS.COLORS.CARD_CONTENT_TITILE;
    iconSrc = IMAGES.ICONS.ARROW_SAME_LEVEL;
    altText = IMAGES.ALT_TEXT.ARROW_SAME_LEVEL;
  } else if (tab === GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME && trend ===  GENERIC_CONSTANTS.TREND.UP){
    style.color = '#039855';
    iconSrc = IMAGES.ICONS.ARROW_UP;
    altText = IMAGES.ALT_TEXT.ARROW_UP;
  }else if (tab === GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME && trend === GENERIC_CONSTANTS.TREND.DOWN){
    style.color = '#FF4405';
    iconSrc = IMAGES.ICONS.ARROW_DOWN;
    altText = IMAGES.ALT_TEXT.ARROW_DOWN;
  }else if (tab === GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME){
    style.color = GENERIC_CONSTANTS.COLORS.CARD_CONTENT_TITILE;
    iconSrc = IMAGES.ICONS.ARROW_SAME_LEVEL;
    altText = IMAGES.ALT_TEXT.ARROW_SAME_LEVEL;
  }
 
  return (
    <div style={style}>
      &nbsp;{percentage === '--%'?'--':percentage}
      {(!percentage?.includes("%") && percentage != '--') && "%"}&nbsp;
      {/* <img
        src={iconSrc}
        alt={altText}
        style={{ width: "20px", height: "18px", marginRight: "8px", }}
      /> */}
    </div>
  );
};

export default TrendIndicator;
