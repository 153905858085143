import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Button, Select, TreeSelect, Switch} from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { setViewType } from "../redux/header/headerSlice";
import GENERIC_CONSTANTS from "../Constants/generic";
import "./Styles/TopFilter.css";
import { getHrbpReporteeList, getManagerReporteeList,setHrbpToggler, setIsReporteeChanged, setIsSpanChanged, setIsTimeLineChanged } from "../redux/topFilter/topFilterSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getTopFilter,updateTopFilterPayLoad} from "../redux/topFilter/topFilterSlice";
import {
  timelineOptionListCreator,
  spanOptionListCreator,
  reporteesOptionListCreator,
  handleInputKeyDown,
  dataSummaryFetch,
  findSelectedReportee,
  updatePayload,
} from "../utils/helpers";
import { getMetricsSummary,getReporteeMetricsSummary } from "../redux/teamMetrics/metricsSlice";
import {getReporteeHealthSummary} from "../redux/teamHealth/healthSlice"
import CONSTANTS from "../utils/constants";
import { getFilterCategory, updateSelectedFilters } from "../redux/filter/filterSlice";
import { Tooltip } from 'antd';

const TopFilter = ({ setViewType }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const managerSelectorRef = useRef(null);

  const [selectedTreeValue, setSelectedTreeValue] = useState('');
  const [managerHrbpTreeData,setManagerHrbpTreeData] = useState([]);
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [istogglerEnabled,setTogglerEnabled] = useState(false);
  const isLoading = useSelector((state) => state.topFilter.topFilter.loading);
  const [selectedViewType, setSelectedViewType] = useState(GENERIC_CONSTANTS.LABELS.DATA);
  const [managerContainerWidth, setManagerContainerWidth] = useState(null);
  const {reporteeListToolTip,spanListToolTip,hrbp,manager,reporteeList,timelineList,spanList } = useSelector(
    (state) => state.topFilter.topFilter.data
  );
  const {hrbpToggler,topFilterPayload,dataSummaryTabKey,isReporteeChanged} = useSelector(state => state.topFilter.topFilter);
  
  const fetchParams = {
    dataSummaryTabKey,
    dispatch,
    getReporteeHealthSummary,
    getReporteeMetricsSummary,
    topFilterPayload
  };

  useEffect(() => {

    /* 
    This useEffect logic executes whenever the span or reportee in the payload changes.
    The reportee in the payload may be changed by:
     - Directly changing the reportee selector
     - Changing the quarter
     - Using the HRBP toggler

    If the user is on the Data Summary page, the component is mounted, 
    and both the reportee and spanText are present in the payload,
    it triggers the dataSummaryFetch function with the appropriate parameters.
    */

    if (
      location.pathname === CONSTANTS.ROUTES.DATA_SUMMARY &&
      isComponentMounted &&
      topFilterPayload?.reportee &&
      topFilterPayload?.span?.spanText
    ) {
      dataSummaryFetch(fetchParams);
    }
    // If the user is only HRBP, the getReporteeSummary post request is need to be called only during initial render
    if((!manager && hrbp) && (location.pathname === CONSTANTS.ROUTES.DATA_SUMMARY) && !isComponentMounted){
      dataSummaryFetch(fetchParams);
    }

    /* Disable refetching of the reporteeList when navigating to the deep dive page.
       This prevents the selected reportee from being reset to the default reportee.*/

      dispatch(setIsReporteeChanged(false));
      dispatch(setIsTimeLineChanged(false));
      dispatch(setIsSpanChanged(false));

  }, [topFilterPayload?.reportee,topFilterPayload?.span]);

  useEffect(() => {
    /* This useEffect executes whenever the timeline changes.
       When the user changes the quarter, it updates the topFilterPayload.
       This update, specifically to topFilterPayload?.timeline, triggers this effect.*/

    // Extract payload without reportee to be sent to getReportee API
    const { reportee, ...payloadWithoutReportee } = topFilterPayload;
    if (location.pathname === CONSTANTS.ROUTES.DATA_SUMMARY && isComponentMounted) {
      // Get Reportee list based on toggler position when Quarter changes
      dispatch(hrbpToggler?getHrbpReporteeList({...payloadWithoutReportee}):getManagerReporteeList({...payloadWithoutReportee}));
    }
    // Reset Filter when Quarter changes
    dispatch(updateSelectedFilters(GENERIC_CONSTANTS.BUTTON_TYPES.RESET));
    // Fetch filter category list when Quarter changes
    topFilterPayload?.timeline?.timelineText && dispatch(getFilterCategory({ ...topFilterPayload?.timeline }));
  }, [topFilterPayload?.timeline]);
  
  useEffect(() => {
    // This useEffect adds the handleResize function to the window event listener when the page mounts.
    // The handleResize function adjusts the width of the toggler based on the screen size.
    const handleResize = () => {
      if (managerSelectorRef?.current) {
        const width = managerSelectorRef.current.clientWidth;
        setManagerContainerWidth(width);
      }
    };
    // Set the initial view type and fetch the top filter data.
    setViewType(GENERIC_CONSTANTS.LABELS.DATA);
    dispatch(getTopFilter());
    // Add the resize event listener and immediately call handleResize to set initial width.
    window.addEventListener("resize", handleResize);
    handleResize();
    // Cleanup the event listener when the component unmounts.
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  useEffect(()=>{

     /* Initially executed for the Summary API call.
     If the user is a manager and the current path is the Data Summary page,
     it dispatches the getMetricsSummary action.
     For HRBP users, the getReporteeSummary request is handled within the 
     useEffect that monitors changes to the topFilter payload  */

    if(manager && location.pathname === CONSTANTS.ROUTES.DATA_SUMMARY)
    dispatch(getMetricsSummary());
  },[manager,hrbp])

  useEffect(()=>{
    
    // This useEffect executes when the reporteeList changes or during initial render
    // When user changes the Quarter , during initial render or toggle changes reporteeList gets updated

    // Update manager tree data based on updated reporteeList
    setManagerHrbpTreeData(reporteesOptionListCreator(reporteeList));
    // Find selected reportee
    const selectedReportee = findSelectedReportee(reporteeList);
    // Update the payload based on the updated selectedreportee
    updatePayload(dispatch,updateTopFilterPayLoad,GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.REPORTEE,selectedReportee);

    setSelectedTreeValue(selectedReportee?.reporteeName);
    // Check during initial render time to enable the toggler.
    if( hrbp && manager){
        setTogglerEnabled(true);
    }
    // handle the case when the user is only HRBP.
    if(!manager && hrbp){
      const selectedHRBP = findSelectedReportee(reporteeList);
      dispatch(setHrbpToggler(true));
      setManagerHrbpTreeData(reporteeList);
      setManagerHrbpTreeData(reporteesOptionListCreator(reporteeList));
      setSelectedTreeValue(selectedHRBP?.reporteeName);
      updatePayload(dispatch,updateTopFilterPayLoad,GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.REPORTEE,selectedHRBP)
    }
    // This flag set to fetch the deep dive data when reportee list get updated due to change in quarter
      dispatch(setIsReporteeChanged(true)); 
  }, [reporteeList])

  useEffect(()=>{

    // This useEffect updates the payload whenever the spanList or timeList changes.

    const selectedTimeline = timelineList?.find(timeline => timeline.selected) || null;
    const selectedSpan = spanList?.find((span) => span.selected)|| null;
    updatePayload(dispatch,updateTopFilterPayLoad,GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.QUARTER,selectedTimeline);
    updatePayload(dispatch,updateTopFilterPayLoad,GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.SPAN,selectedSpan);

  },[spanList,timelineList])

  const viewTypeChange = (renderType) => {
    setViewType(renderType);
    setSelectedViewType(renderType);
  };

  const dataSummaryClickHandler = (renderType) => {
    viewTypeChange(renderType);
    dataSummaryFetch(fetchParams);
    navigate(CONSTANTS.ROUTES.DATA_SUMMARY);
  };

  const handleManagerHrbpToggler = (value)=>{

    // Handle when HRBP toggler changes
    const { reportee, ...payloadWithoutReportee } = topFilterPayload;
    dispatch(setHrbpToggler(!!value));
    // Fetch reporteeList based on the toggler position
    value?dispatch(getHrbpReporteeList((payloadWithoutReportee))):dispatch(getManagerReporteeList(payloadWithoutReportee));
   // Set the flag to indicate that component mounting is complete.
    setIsComponentMounted(true); 
   
  }

  const handleSelectChange = (type, option) => {

    managerSelectorRef?.current?.blur();
    switch (type) {
      case GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.REPORTEE:
        setSelectedTreeValue(option?.reporteeName);
        dispatch(setIsReporteeChanged(true));
        break;
      case GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.QUARTER:
        dispatch(setIsTimeLineChanged(true));
        break;
      case GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.SPAN:
        dispatch(setIsSpanChanged(true));
        break;
      default:
        // Handle default case if needed
        break;
    }
    updatePayload(dispatch,updateTopFilterPayLoad,type,option);
    // Set the flag to indicate that component mounting is complete.
    setIsComponentMounted(true);
  };


  return (
    <Row justify="space-between" className="superFilterBar">
      <Col lg={14} md={20} xs={24} className="top-filter">

        <Col className="top-filter-quarter">
          <label className="top-filter-label">Quarter</label>
          <Select
            virtual={false}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            bordered={false}
            optionLabelProp="label"
            options={timelineOptionListCreator(timelineList)}
            value={topFilterPayload?.timeline?.timelineText}
            onSelect={(value, option) => handleSelectChange(GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.QUARTER,option)}
            className="superFilterTab__dropdown w-269"
            placeholder="Select Quarter"
            loading={isLoading}
          />
        </Col>
        <Col className="top-filter-manager">
          <div className="top-filter-manager-label">
            <Tooltip title = {reporteeListToolTip?.trim()} color="#F9F5FF">
               <p>Manager</p>
            </Tooltip>
           
          </div>
          <div ref={managerSelectorRef}>
          {!isLoading && <TreeSelect
              dropdownStyle={{width:managerContainerWidth?managerContainerWidth:'auto'}}
              bordered={false}
              className="superFilterTab__treeselect"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch
              virtual={false}
              style={{ width: istogglerEnabled ? "70%" : "98%" }}
              value={selectedTreeValue}
              placeholder="Select Manager"
              allowClear
              onSelect={(value, option) => handleSelectChange(GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.REPORTEE, option)}
              treeData={managerHrbpTreeData}
              treeDefaultExpandedKeys = {[managerHrbpTreeData?.[0]?.value]}
              loading={isLoading}
              onInputKeyDown={handleInputKeyDown}
            />}
            {isLoading && <TreeSelect
              dropdownStyle={{width:managerContainerWidth?managerContainerWidth:'auto'}}
              className="superFilterTab__treeselect"
              bordered={false}
              style={{ width: istogglerEnabled ? "70%" : "98%" }}
              value={selectedTreeValue}
              placeholder="Select Manager"
              treeData={[]}
              loading={isLoading}
            />}
         
            {istogglerEnabled && (
              <Switch
                className="tree-select-switch"
                checkedChildren="HRBP"
                unCheckedChildren="Manager"
                onChange={handleManagerHrbpToggler}
              />
            )}
          </div>
        </Col>
        {  <Col className={`top-filter-span ${selectedViewType === GENERIC_CONSTANTS.LABELS.ACTIONS?"hidden":null}`}>
          <Tooltip title = {spanListToolTip?.trim()} color="#F9F5FF">
            <label className="top-filter-label">Span</label>
          </Tooltip>
          
          <Select
            placeholder="Select Span"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            bordered={false}
            optionLabelProp="label"
            options={spanOptionListCreator(spanList)}
            value={topFilterPayload?.span?.spanText}
            onSelect={(value, option) => handleSelectChange(GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.SPAN, option)}
            className="superFilterTab__dropdown w-116"
            loading={isLoading}
          />
        </Col>
        }
      </Col>
      <Col lg={8} md={4} xs={18} className="superFilterTab">
        <Button
          disabled={isLoading}
          className={`superFilterTab__button ${
            selectedViewType === "DATA" ? "superFilterTab__button_active" : ""
          }`}
          onClick={() => dataSummaryClickHandler(GENERIC_CONSTANTS.LABELS.DATA)}
        >
          Data Summary
          {/* <span className="badge">2</span> */}
        </Button>
        <Button
          disabled={isLoading}
          className={`superFilterTab__button ${
            selectedViewType === "ACTIONS"
              ? "superFilterTab__button_active"
              : ""
          }`}
          onClick={() => {
            viewTypeChange(GENERIC_CONSTANTS.LABELS.ACTIONS);
            navigate("/ui");
          }}
          style={{ marginLeft: "8px" }}
        >Action Plans</Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setViewType,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopFilter);

