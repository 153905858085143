import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
};

const metricsSummarySlice = createSlice({
  name: 'metricsSummary',
  initialState,
  reducers: {
    ...createFetchReducer('getMetricsSummary'),
    ...createFetchReducer('getReporteeMetricsSummary'),
    resetMetricsSummary: (state) => {
      return initialState;
    }
}});

export const {

  getMetricsSummary,
  getMetricsSummarySuccess,
  getMetricsSummaryFailure,

  getReporteeMetricsSummary,
  getReporteeMetricsSummarySuccess,
  getReporteeMetricsSummaryFailure,
  resetMetricsSummary,
 
} = metricsSummarySlice.actions;

export default combineReducers({
  metricsSummary: metricsSummarySlice.reducer
});
