import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getMetricsSummary = () => {
	return axiosInstance.get(apiPaths.getMetricsSummary);
};
const getReporteeMetricsSummary = (payload) => {
	return axiosInstance.post(apiPaths.getReporteeMetricsSummary,payload);
};

export default {
	getMetricsSummary,
	getReporteeMetricsSummary
};

