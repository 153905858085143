import { Button } from "antd";
import GENERIC_CONSTANTS from "../Constants/generic";
import Remarks from "../components/Remarks";
import ActionControlButton from "../components/ActionControlButton";
import { CustomContainer, Footer } from "./StyledComponents";
import { message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import AttritionChartByGender from "../components/Charts/AttritionCharts/AttritionChartByGender";
import AttritionChartByPerfomance from "../components/Charts/AttritionCharts/AttritionChartByPerfomance";
import AttritionChart from "../components/Charts/AttritionCharts/AttritionChart";
import AttritionChartByGrade from "../components/Charts/AttritionCharts/AttritionChartByGrade";

export const generateRandomData = (count) => {
  const data = [];
  for (let i = 1; i <= count; i++) {
    data.push({
      key: i.toString(),
      name: `Better Oppurtunity ${i}`,
      age: Math.floor(Math.random() * 30) + 20, // Random age between 20 and 50
      address: `Address ${i}`,
      email: `user${i}@example.com`,
    });
  }
  return data;
};

export const checkDataAvailability = (chartData) => {
  return (
    chartData?.series?.length &&
    chartData?.series?.filter((series) => series?.data?.length).length &&
    chartData?.series?.some((series) => series?.data?.some(value => value !== 0))
  );
};

export const dataWrapper = (data,tab) => {
  // let firstSpan = 0;
  // let prevSpan = 0;
  // let prevCol = 0;

  // sorting based on heading
  const sortedData = data?.slice().sort((a, b) => {
    // if (a.Col0?.tag< b.Col0?.tag) {
    //   return -1;
    // }
    // if (a.Col0?.tag > b.Col0?.tag) {
    //   return 1;
    // }
  
    // If Col0 values are equal, then compare by Col1.due
    if ( tab === GENERIC_CONSTANTS.ACTION_TABS.OPEN && a.Col1?.assignedDate < b.Col1?.assignedDate) {
      return 1;
    }
    if ( tab === GENERIC_CONSTANTS.ACTION_TABS.OPEN && a.Col1?.assignedDate > b.Col1?.assignedDate) {
      return -1;
    }

    if ( tab === GENERIC_CONSTANTS.ACTION_TABS.COMPLETED && a.Col1?.completedDate < b.Col1?.completedDate) {
      return 1;
    }

    if ( tab === GENERIC_CONSTANTS.ACTION_TABS.COMPLETED && a.Col1?.completedDate < b.Col1?.completedDate) {
      return -1;
    }
      // If added on date values are equal, then compare by Col1.due
    if (a.Col1?.dueBy < b.Col1?.dueBy) {
      return -1;
    }
    if (a.Col1?.dueBy > b.Col1?.dueBy) {
      return 1;
    }
    return 0;
  });
  //  adding  span
  // const dataWithSpan = sortedData.map((obj) => {
  //   const heading = obj.Col0?.tag;
  //   let span = sortedData.filter((obj1) => obj1.Col0?.tag === heading).length;
  //   return { ...obj, span };
  // });

  // // adding rowNo
  // const dataWithColumn = dataWithSpan.map((obj, index) => {
  //   if (index === 0) {
  //     firstSpan = obj.span;
  //   }
  //   if (index < firstSpan) {
  //     prevSpan = obj.span;
  //     return { ...obj, rowNo: 0 };
  //   }
  //   if (index === prevCol + prevSpan) {
  //     let rowNo = prevSpan + prevCol;
  //     prevSpan = obj.span;
  //     prevCol = rowNo;
  //   }

  //   return { ...obj, rowNo: prevCol };
  // });


  return sortedData;
};

export const managerOptionListCreator = (reporteeList) => {
  return reporteeList?.map((assignee) => ({
    value: assignee.reporteeEmail,
    label: (
      <div style={{ padding: "4px",textAlign:"left" }}>
        <div >{assignee.reporteeName}</div>
        <div style={{ fontSize: 10, color: "rgba(0, 0, 0, 0.45)" }}>
          {assignee.positionName}
        </div>
      </div>
    ),
  }));
};

export const reporteesOptionListCreator = (reporteeList) => {
  return reporteeList?.map((manager) => ({
    value: manager?.reporteeName+manager?.value,
    title: (
      <div style={{ margin: "0px", padding: "0px" }}>
        <p style={{ margin: "0px", padding: "0px", fontSize: "14px", lineHeight: "1.8" }}>{manager?.reporteeName}</p>
        <p style={{ margin: "0px", padding: "0px", lineHeight: "1.4", fontSize: "12px", color: "rgba(0, 0, 0, 0.45)" }}>
          {manager?.positionName}
        </p>
      </div>
    ),
    reporteeName:manager?.reporteeName,
    positionName:manager?.positionName,
    reporteeEmail:manager?.reporteeEmail,
    children: manager?.heirarchy?.[0]?.value ? reporteesOptionListCreator(manager.heirarchy) : []

  }));
};

export const timelineOptionListCreator = (timelineList) => {
  return timelineList?.map((timeline) => ({
    value: timeline?.timelineText,
    label: timeline?.timelineText,
    timelineText:timeline?.timelineText,
    year:timeline?.year
  }));
};

export const spanOptionListCreator = (spanList) => {
  return spanList?.map((span) => ({
    value: span?.spanText,
    label: span?.spanText,
    spanText: span?.spanText,
  }));
};

export const findSelectedReportee = (reporteeList) => {
  for (const reportee of reporteeList ?? []) {
    if (reportee.selected) {
      return reportee;
    }
    if (reportee.hierarchy && reportee.hierarchy.length > 0) {
      const selectedReportee = findSelectedReportee(reportee.hierarchy);
      if (selectedReportee) {
        return selectedReportee;
      }
    }
  }
  return null;
};

export const updatePayload = (dispatch, updateTopFilterPayLoad, type, data) => {

  switch (type) {
    case GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.REPORTEE:
      dispatch(
        updateTopFilterPayLoad({
          reportee: {
            reporteeName: data?.reporteeName,
            positionName: data?.positionName,
            reporteeEmail: data?.reporteeEmail,
            selected: true,
          },
        })
      );
      break;
    case GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.QUARTER:
      dispatch(
        updateTopFilterPayLoad({
          timeline: {
            timelineText: data?.timelineText,
            selected: true,
            year: data?.year
          }
        })
      );
      break;
    case GENERIC_CONSTANTS.TOP_FILTER.SELECTOR.SPAN:
      dispatch(
        updateTopFilterPayLoad({
          span: {
            spanText: data?.spanText,
            selected: true,
          },
        })
      );
      break;
  }
}


export const sortTableColumnsByKey = (columns)=>{

  const keyTrimmedColumns =  columns?.map(item=>({...item,key:item?.key?.trim()}));
  return keyTrimmedColumns?.slice().sort((a, b) => {
    if (a.key < b.key) return -1;
    if (a.key > b.key) return 1;
    return 0;
  });

}
export const actionTableColumnRender = (columns, tab) => {


  const sortedColumns = sortTableColumnsByKey(columns);

  const columnsData = [
    {
      title:sortedColumns[0]?.title,
      dataIndex:sortedColumns[0]?.title,
      key:sortedColumns[0]?.title,
      width:'18%',
      render: (text, record, index) => {
        // let rowSpan =
        // index === 0 ? record.span : index === record.rowNo ? record.span : 0;
        return {
          children: (
            <div className="table_focusArea_text_container">
              <div>
                {record?.Col0?.tag && <Button type="default">{record?.Col0?.tag}</Button>}
                {record?.Col0?.text && <p>{record?.Col0?.text}</p>}
              </div>
            </div>
          ),
          // props: {
          //   rowSpan: rowSpan,
          // },
        };
      },
    },
    {
      title:sortedColumns[1]?.title,
      dataIndex:sortedColumns[1]?.title,
      key:sortedColumns[1]?.title,
      render: (text, record) => (
        <CustomContainer>
          <div className="table_action_text_container">
            <p style={{whiteSpace:"pre-wrap"}}>{record?.Col1?.text}</p>
            
            {tab === GENERIC_CONSTANTS.ACTION_TABS.OPEN && (
              <ActionControlButton id={record?.Col1?.actionId} controllables = {
                {
                  editable:record?.Col1?.editable,
                  deletable:record?.Col1?.deletable,               
                  completable:record?.Col1?.completable,
                }

              } />
            )}
          </div>

          <Footer>
            <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
              <div className="table_action_footer">
                <span>Assigned by: {record?.Col1?.assignedBy}</span>
                {tab === GENERIC_CONSTANTS.ACTION_TABS.OPEN && record?.Col1?.assignedDate !== null &&
                <span>Added on: {dayjs(record?.Col1?.assignedDate).format('DD MMM YY')}</span> 
                }
                 {tab === GENERIC_CONSTANTS.ACTION_TABS.COMPLETED && record?.Col1?.completedDate !== null &&
                <span>Completed on: {dayjs(record?.Col1?.completedDate).format('DD MMM YY')}</span> 
                }
                {record?.Col1?.dueBy !== null && <span>Due by: {dayjs(record?.Col1?.dueBy).format('DD MMM YY')}</span> }
                
                
              </div>
            </div>
          </Footer>
        </CustomContainer>
      ),
    },
    {
      title:sortedColumns[sortedColumns?.length-1]?.title,
      dataIndex:sortedColumns[sortedColumns?.length-1]?.title,
      key:sortedColumns[sortedColumns?.length-1]?.title,
      width:'18%',
      render: (text, record) => {
        return (
          <CustomContainer className="table_remarks_text_container">
            <p>{record?.Col3}</p>
            
              <div>
                <Remarks actionId = {record?.Col1?.actionId} tab={tab}/>
              </div>
            
          </CustomContainer>
        );
      },
    },
  ];

  return columnsData;
};

export const successMessage = (messageText)=>{

  return message.success({
    content: messageText,
    icon: <CheckCircleOutlined style={{ color: '#7F56D9' }} />,
  })
}
export const errorMessage = (messageText)=>{

  return message.error({
    content: messageText,
    duration: 5,
  })
}
export const chartTooltip = {
  trigger: "axis",
  axisPointer: {
    type: "cross",
    crossStyle: {
      color: "#999",
    },
  },
  formatter: function (params) {
    let tooltip = `${params[0].axisValueLabel}<br/>`;
    params.forEach((param) => {
      if(param?.seriesName?.includes("%") && param.value !== '-'){
        tooltip += `${param.seriesName}: ${param.value}%<br/>`;
      }
      else if (param.value === '-'){
        tooltip += `${param.seriesName}: ${0}%<br/>`;
      }
      else{
        tooltip += `${param.seriesName}: ${param.value}<br/>`;
      }
     
    });
    return tooltip;
  },
}

export const chartLegends =  {
  data: [],
  itemStyle: {},
  bottom: 23, // Adjust this value to position the legend
  left: 20,
  itemGap: 10,
  itemWidth: 10,
  itemHeight: 10,
  
}
 export const chartXaxis =  {
  type: "category",
  data:  [],
  axisTick: { 
    // Remove axisTick
    show: false
  },
  axisLabel: {
    color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
    interval: 0,
    rotate: 0,
    margin: 25,
    formatter: function (value) {
      // Split the value by "-" to separate month and year
      const parts = value.split("-");
      // Return the formatted label with the month on the first line and the year on the second line
      return `\t\t${parts[0]}\n${parts[1]}`;
    },

  },
}

export const chartYaxis = {
  type: "value",
  data:  [],
  axisLabel: {
    color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
    interval: 0,
    rotate: 0,
    margin: 5,
  },  
}

export const chartGrid = {
  
    left: "5.5%",
    top: "7.9%",
    bottom: "32%",
    right: "3%",
    // Adjust the left padding to move the chart to the left
    // Other grid configurations...
}

export const chartSeriesData = (chartData,color,position,stack,labelColor,yIndex) => {

  return chartData?.series?.map((series,index) => {
    return {
      name: series?.name || "name1",
      data: series?.data?.map((item,dataIndex)=>{
        return {
          value:(item === 0 & series?.type !== 'line')? '-' : item,
          itemStyle: {
            borderRadius: item<0?[0,0,8,8]:stack === 'ad'?index === 1?[8,8,0,0]: (chartData?.series?.[1].data[dataIndex] === 0 || chartData?.series?.[1].data[dataIndex] <0) ?[8,8,0,0]:[0,0,0,0]:[8,8,0,0],
          },

          label:{
            position:item<0?'insideBottom':position[index],
            offset: [0, 5], 
          }
        }
      })|| [],
      type: series?.type || "bar",
      barWidth: "36",
      barGap: "0%",
      barMinHeight:10,
      emphasis: {
        focus: 'series'
      },
      yAxisIndex:(series?.type === 'line')?yIndex:0,
      stack: (series?.type !== 'line')?stack:null,
      itemStyle: {
        normal: {
          color:  color[index],
          label: {
            show: true,
            color: labelColor && index === 0?labelColor:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
            position: position[index],
            formatter: function (params) {
              // Check if the value is zero
              if (params.value === 0) {
                return ""; // Return an empty string to hide the label
              } else if (series?.type === 'line' && series?.name !== 'Headcount Change') {
                return `${params.value}%`; // Return the value to display the label
              } else if (series?.type === 'line' && series?.name === 'Headcount Change') {
                return `${params.value}`; // Return the value to display the label
              } 
              else{
                return params.value;
              }
            },
          },
        },
      },
    };
  });
};

export const handleInputKeyDown = (event) => {
  const { key, target } = event;
  const { selectionStart } = target;
  if (key === ' ' && selectionStart === 0) {
    // If space is pressed at the beginning of the input, prevent default
    event.preventDefault();
  } else if (/[^a-zA-Z\s]/.test(key)) {
    // Prevent default behavior for non-alphabetic characters except space
    event.preventDefault();
  }
};

export const dataSummaryFetch = ({
  dataSummaryTabKey,
  dispatch,
  getReporteeHealthSummary,
  getReporteeMetricsSummary,
  topFilterPayload
}
  
) => {
  dataSummaryTabKey === GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TAB_KEY
    ? dispatch(getReporteeHealthSummary(topFilterPayload))
    : dispatch(getReporteeMetricsSummary(topFilterPayload));
};
export const resizeChart = (chartRef,echarts) => {
  
  const resizeObserver = new ResizeObserver(()=>{
    if (chartRef.current) {
      const chart = echarts.getInstanceByDom(chartRef.current);
      chart.resize();
    }
  });
  chartRef?.current &&  resizeObserver.observe(chartRef.current);
  return resizeObserver;
};

export const getAlertMsg = () => {
  return (
    <div  className = "trend_alert_container">
      <div>Note:</div>
      <ul>
        <li>Red / Green / Grey are basis comparison with your function, org. average</li>
      </ul>
    </div>
  );
};


export const AttritionChartItems = [
  {
    key: "1",
    label: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.ATTRITION_CHART_TABS.GRADE.TITLE,
    children: <AttritionChartByGrade/> ,
  },
  // {
  //   key: "2",
  //   label: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.ATTRITION_CHART_TABS.PERFOMANCE.TITLE,
  //   children:  <AttritionChartByPerfomance/>,
  // },
  {
    key: "3",
    label: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.ATTRITION_CHART_TABS.GENDER.TITLE,
    children:  <AttritionChartByGender/>,
  },
  {
    key: "4",
    label: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.ATTRITION_CHART_TABS.ATTRITION_TYPE.TITLE,
    children: <AttritionChart/>,
  },
];

export const funnelSelectionListCreator = (funnelSelectionList) => {
  
  // Check if funnelSelectionList is provided and is an array
  if (!funnelSelectionList || !Array.isArray(funnelSelectionList)) {
      return []; // Return an empty array if funnelSelectionList is invalid
  }

   // Trim white space from both ends of each option
  const trimmedList = funnelSelectionList?.map(option => option?.trim());
  // Sort the funnelSelectionList alphabetically
  const sortedFunnelSelectionList = trimmedList?.slice()?.sort((a, b) => a.localeCompare(b)); 

  // Map the sorted array to objects with value and label properties
  return sortedFunnelSelectionList?.map((option) => ({
      value: option,
      label: option,
  }));
};

export const validateAction = (value)=>{
  let regex = GENERIC_CONSTANTS.REGEX_PATTERN.ACTION_DESCRIPTION;
  if(value && regex.test(value)){
    return true;
  }
  return false;
}