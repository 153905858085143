export const createFetchReducer = (name) => {
  return {
    [`${name}`]: (state) => {
      state.loading = true;
      state.loaded = false;
      state.error = null;

    },
    [`${name}Success`]: (state, action) => {
      state.loading = false;
      state.loaded = true; 
      state.data = (name  === 'getManagerReporteeList' ||  name === 'getHrbpReporteeList') ?{...state.data,reporteeList:action.payload.reporteeList}:action.payload
      state.error = null;
    },
    [`${name}Failure`]: (state, action) => {
      state.loading = false;
      state.loaded = true;
      state.error = action.payload;
      state.data = [];
    },
  };
};
