import { call, put, takeEvery } from 'redux-saga/effects';
import GENERIC_CONSTANTS from '../../Constants/generic'
import {
  getActionsList,
  getActionsListSuccess,
  getActionsListFailure,

  addAction,
  addActionSuccess,
  addActionFailure,

  editAction,
  editActionSuccess,
  editActionFailure,

  markComplete,
  markCompleteSuccess,
  markCompleteFailure,

  getRemarksList,
  getRemarksListSuccess,
  getRemarksListFailure,

  deleteAction,
  deleteActionSuccess,
  deleteActionFailure
} from './actionsSlice';
import API from './api';
import { successMessage ,errorMessage} from '../../utils/helpers';

function* fetchActionsListAsync(action) {
  try {
    const response = yield call(API.getActionsList, action.payload);
    yield put(getActionsListSuccess(response.data));
  } catch (error) {
    yield put(getActionsListFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

function* fetchAddActionAsync(action) {
  try {
    const response = yield call(API.createAction, action.payload);
    yield put(addActionSuccess(response.data));
    successMessage(GENERIC_CONSTANTS.TOAST_MESSAGES.SUCCESS.ADD);
  } catch (error) {
    errorMessage(error.response?.data?.message);
    const {actionData,...payload} = action.payload;
    yield call(fetchActionsListAsync,{payload}); 
  }
}

function* fetchEditActionAsync(action) {
  
  try {
    const response = yield call(API.editAction, action.payload);
    yield put(editActionSuccess(response.data));
    successMessage(GENERIC_CONSTANTS.TOAST_MESSAGES.SUCCESS.EDIT);
  } catch (error) {
    errorMessage(error.response?.data?.message);
    const {actionData,...payload} = action.payload;
    yield call(fetchActionsListAsync,{payload}); 
  }
}

function* fetchmarkCompleteActionAsync(action) {
  
  try {
    const response = yield call(API.markComplete, action.payload);
    yield put(markCompleteSuccess(response.data));
    successMessage(GENERIC_CONSTANTS.TOAST_MESSAGES.SUCCESS.MARK);
  } catch (error) {
    errorMessage(error.response?.data?.message);
    const {actionData,...payload} = action.payload;
    yield call(fetchActionsListAsync,{payload}); 
  }
}

function* fetchDeleteActionAsync(action) {
  try {
    const response = yield call(API.deleteAction, action.payload);
    yield put(deleteActionSuccess(response.data));
    successMessage(GENERIC_CONSTANTS.TOAST_MESSAGES.SUCCESS.DELETE);
  } catch (error) {
    errorMessage(error.response?.data?.message);
    const {actionData,...payload} = action.payload;
    yield call(fetchActionsListAsync,{payload}); 
  }
}

function* fetchRemarksListAsync(action) {
  try {
    const response = yield call(API.getRemarksList, action.payload);
    yield put(getRemarksListSuccess(response.data));
  } catch (error) {
    yield put(getRemarksListFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* actionsSaga() {
  yield takeEvery(getActionsList.type, fetchActionsListAsync);
  yield takeEvery(addAction.type, fetchAddActionAsync);
  yield takeEvery(editAction.type, fetchEditActionAsync);
  yield takeEvery(markComplete.type, fetchmarkCompleteActionAsync);
  yield takeEvery(deleteAction.type, fetchDeleteActionAsync);
  yield takeEvery(getRemarksList.type, fetchRemarksListAsync);
}
