import React, { useState } from 'react';
import { StyledList, StyledListItem, IndexColumn, TextColumn, TextBold } from "../utils/StyledComponents"
import "../pages/DataSummary/Styles/TeamMetrics.css"
import SeeMoreButton from './SeeMoreButton';
import GENERIC_CONSTANTS from "../Constants/generic";
import StatusIndicator from "../components/StatusIndicator";
import { Tooltip } from 'antd';


const NestedNumberList = ({ cardItemList, titleIndex }) => {

  const getLetter = (index) => String.fromCharCode(97 + index);
  const [expandedItems, setExpandedItems] = useState({});
  const [expandedchildItems, setExpandedchildItems] = useState({});
  const toggleChildExpansion = (index) => {
    setExpandedchildItems(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  const toggleExpansion = (index) => {
    console.log(index)
    setExpandedItems(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const isLevelThree = ()=>{

    return cardItemList?.filter(item=>!item.cardItemList?.length).length === cardItemList?.length;
  } 

  const isOnlyOneChild = ()=>{
    return cardItemList?.length === 1  ? true :false;
  }

  const renderChildItems = (item, index) => (
    <div style={{display:"flex",justifyContent:"start"}}>
      <IndexColumn>
        <span className='attrition_item_content_child margin_left-4'>{getLetter(index)}.</span>
      </IndexColumn>
        <TextColumn>
        <Tooltip title = {item?.toolTip?.trim()} color="#F9F5FF">
          <span className='attrition_item_content_child'>{item.displayText}</span>
        </Tooltip>
      </TextColumn>
    </div>
  );

  const renderItems = (item, index) => {
    
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            paddingRight: "12px",
          }}
        >
          {!isOnlyOneChild() && (
            <IndexColumn>
              <span
                style={{
                  fontWeight: isLevelThree() ? "400" : "500",
                  color: isLevelThree() ? "#667085" : "#475467",
                }}
                className="attrition_item_content margin_left-6"
              >
                {index + 1}.
              </span>
            </IndexColumn>
          )}
            <TextColumn>
            <Tooltip title={item?.toolTip?.trim()} color="#F9F5FF">
            <span
              style={{
                fontWeight: isLevelThree() ? "400" : "500",
                color: isLevelThree() ? "#667085" : "#475467",
              }}
              className="attrition_item_content"
            >
              {item.displayText}
            </span>
            </Tooltip>
           </TextColumn>
          {item?.statisticsVal && (
            <div>
              <StatusIndicator
                percentage={item?.statisticsVal}
                trend={item?.statisticsTrendInd}
                tab={GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TAB_NAME}
              />
            </div>
          )}
        </div>

        {!expandedchildItems[index] ? (
          <StyledList>
            {item?.cardItemList &&
              item?.cardItemList.map((child, childIndex) => (
                <StyledListItem key={childIndex}>
                  {childIndex <= GENERIC_CONSTANTS.LIST_LENGTH - 1 &&
                    renderChildItems(child, childIndex)}
                  {childIndex === GENERIC_CONSTANTS.LIST_LENGTH && (
                    <SeeMoreButton
                      title={"See More"}
                      onClick={() => toggleChildExpansion(index)}
                    />
                  )}
                </StyledListItem>
              ))}
          </StyledList>
        ) : (
          <StyledList>
            {item?.cardItemList &&
              item?.cardItemList.map((child, childIndex) => (
                <StyledListItem key={childIndex}>
                  <>{renderChildItems(child, childIndex)}</>
                  {childIndex === item?.cardItemList.length - 1 && (
                    <SeeMoreButton
                      title={"See Less"}
                      onClick={() => toggleChildExpansion(index)}
                    />
                  )}
                </StyledListItem>
              ))}
          </StyledList>
        )}
      </>
    );}

  return (
    <>
      {

        !expandedItems[titleIndex] ? (

          <StyledList>
            {cardItemList && cardItemList.map((item, index) => (

              <StyledListItem key={index}>
                {typeof item === 'object' ? (
                  <>
                    {index <= GENERIC_CONSTANTS.LIST_LENGTH-1 && renderItems(item, index)}
                    {index === GENERIC_CONSTANTS.LIST_LENGTH && <SeeMoreButton title={'See More'} onClick={() => toggleExpansion(titleIndex)} />}
                  </>
                ) : (
                  <>
                    <IndexColumn>{index + 1}.</IndexColumn>
                    <TextColumn>{item}</TextColumn>
                  </>
                )}
              </StyledListItem>
            ))}
          </StyledList>
        ) : (<StyledList>
          {cardItemList && cardItemList.map((item, index) => (
            <StyledListItem key={index}>
              {typeof item === 'object' ? (
                <>
                  {(
                    <>
                      {renderItems(item, index)}
                      {index === cardItemList.length - 1 && <SeeMoreButton title={'See less'} onClick={() => toggleExpansion(titleIndex)} />}
                    </>
                  )}
                </>
              ) : (
                <>
                  <IndexColumn>{index + 1}.</IndexColumn>
                  <TextColumn>{item}</TextColumn>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>)
      }
    </>


  );
};

export default NestedNumberList;
