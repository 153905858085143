import React from 'react';
import { Layout } from 'antd';
import ActionComponent from "./ActionComponent";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ textAlign: 'center', background: '#FBF8FF'}}>
      <ActionComponent />
    </Footer>
  );
};

export default AppFooter;
