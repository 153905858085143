import React, { useEffect } from "react";
import * as echarts from "echarts";
import { Row, Col } from "antd";
import "./styles/chartStyle.css";
import { checkDataAvailability } from "../../../utils/helpers";
import { DataNotAvailable } from "../../DataNotAvailable";
import { ErrorComponent } from "../../ErrorComponent";
import { Loader } from "../../Loader";
import { useSelector } from "react-redux";
import GENERIC_CONSTANTS from "../../../Constants/generic";

const VerticalPyramid = ({ chartObj }) => {
  const chartData = useSelector(
    (state) => state.structureDeepdive.structureDeepdive.data?.pyramid
  );
  const isLoading = useSelector(
    (state) => state.structureDeepdive.structureDeepdive.loading
  );
  const structureError = useSelector(
    (state) => state.structureDeepdive.structureDeepdive.error
  );
  const topFilterError = useSelector(
    (state) => state.topFilter.topFilter.error
  );
  const error = structureError || topFilterError;

  const { domName, title } = chartObj;
  const maxDataValue = Math.max( chartData?.series?.[0]?.data.reduce((max,value)=>max>value?max:value,0),
  chartData?.series?.[1]?.data.reduce((max,value)=>max>value?max:value,0)
  )
  useEffect(() => {
    var chartDom = document.getElementById(domName);
    var chart = echarts.init(chartDom);
    const options = {
      legend: {
        data: chartData?.legend?.data,
        left: 5,
        bottom: -7,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "red",
        borderRadius: 100,
        borderWidth: 0,
        itemGap: 10,
        itemWidth: 10,
        itemHeight: 8,
        
      },
      grid: {
        left: "3%",
        top: "5%",
        bottom: "7.5%",
        right: "3%",
        containLabel: true,
      },
      xAxis: {
        type: chartData?.xaxis?.type || "value",
        axisLabel:{
          color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
          show:false,
         
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          // Remove axisTick
          show: false,
        },
        splitLine: {
          show: false, // Hide vertical grid lines along the x-axis
        },
        
      },

      yAxis: {
        type: chartData?.yaxis?.type || "category",
        data: chartData?.yaxis?.data || [],
        axisLabel: {
          color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
          margin:40,
          align: "right",
          width :250,
          overflow:'truncate'
         
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: function (params) {
          console.log(params);
          let tooltip = `${params[0].axisValueLabel}<br/>`;
          params.forEach((param) => {
            tooltip += `${param.seriesName}: ${chartData?.series[param.seriesIndex].data[param.dataIndex]}<br/>`;
          });
          return tooltip;
        },
      },
      series: [
        {
          name: chartData?.series[0]?.name || "Male",
          type: chartData?.series[0]?.type || "bar",
          stack: "positions",
          barWidth:'60%',
          data: chartData?.series[0]?.data?.map((item)=>{
            return {
              value:item === 0 ? "-":item < maxDataValue * 0.01 ? maxDataValue * 0.01 : item,
              label:{
                formatter:`${item}`,
              },
            }
          })|| [],
          label: {
            color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
            show: true,
            position: "left",
            distance:10,
          },
          itemStyle: {
            color: "#05C3DE",
            barBorderRadius: [3, 0, 0, 3]
            
          },
         
        },
        {
          name: chartData?.series[1]?.name || "Female",
          type: chartData?.series[1]?.type || "bar",
          stack: "positions",
          label: {
            color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
            show: true,
            position: "right",
            distance:10,
          },
          data: chartData?.series[1]?.data?.map((item)=>{
            return {
              value:item === 0 ?"-":item < maxDataValue * 0.01 ? maxDataValue * 0.01 : item,
              label:{
                formatter:`${item}`,
              }
            }
          })|| [],
          itemStyle: {
            color: "#FFA3B5",
            barBorderRadius: [0, 3, 3, 0]
          },
        },
      ],


    };

    checkDataAvailability(chartData) ? chart.setOption(options) : chart.clear();
  }, [chartData]);
  return (
    <div className="chartContainer box-shadow" style={{ width: "70%" ,height:"auto" ,minHeight:"380px"}}>
      <Row className="StyledRow" gutter={0}>
        <Col xs={24}>
          <div className="tabTitle">{title}</div>
          <div className="subTitle-pyramid">Split By Level</div>
        </Col>
        <Col xs={24}>
          {isLoading && <Loader />}
          {error && !isLoading && <ErrorComponent error={error} />}
          {!checkDataAvailability(chartData) && !error && !isLoading && (
            <DataNotAvailable />
          )}
          <div
            id={domName}
            className="chartContent"
            style={{ width: "100%" ,height:"auto",minHeight:"320px" }}
          ></div>
        </Col>
      </Row>
    </div>
  );
};

export default VerticalPyramid;
