import React from "react";
import StructureTab from "../../../components/Charts/StructureCharts/StructureTab";
import VerticalPyramid from "../../../components/Charts/StructureCharts/VerticalPyramid";
import TransferTab from "../../../components/Charts/StructureCharts/TransferTab";
import RecieverRoles from "../../../components/Charts/StructureCharts/structureTables/RecieverRoles";
import FeederRoles from "../../../components/Charts/StructureCharts/structureTables/FeederRoles";
import GENERIC_CONSTANTS from "../../../Constants/generic"
import { useGetDeepDiveData } from "../../../utils/hooks/useGetDeepDiveData";

const Structure = () => {

  useGetDeepDiveData(GENERIC_CONSTANTS.REDUCER_NAMES.STRUCTURE_DEEPDIVE);
  return (
    <>
      <div className="chartGap">
        <StructureTab chartObj={{ title: "Structure By" }}/>
      </div>
      <div className="chartGap"  >
        <VerticalPyramid 
          chartObj={{ domName: "Organization", 
          title: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.STRUCTURE_CHART_TABS.PYRAMID.TITLE}}/>
      </div>
      {/* <div className="chartGap" >
        <TransferTab chartObj={{ domName: "hiring-3", title: "Transfer By" }}/>
       </div>
       <div className="chartGap">
        <FeederRoles chartObj={{  title: "Feeder Roles",subtitle:"Transfer In" }}/>
      </div>
      <div className="chartGap">
        <RecieverRoles chartObj={{  title: "Receiver Roles",subtitle:"Transfer Out" }}/>
      </div> */}
    </>
  );
};

export default Structure;