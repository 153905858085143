import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getTrendDeepdive,
  getTrendDeepdiveSuccess,
  getTrendDeepdiveFailure,
} from './trendSlice';
import API from './api';

function* fetchTrendDeepdiveAsync(action) {
  try {
    const response = yield call(API.getTrendDeepDive, action.payload);
    yield put(getTrendDeepdiveSuccess(response.data));
  } catch (error) {
    yield put(getTrendDeepdiveFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* trendSaga() {
  yield takeEvery(getTrendDeepdive.type, fetchTrendDeepdiveAsync);
}
