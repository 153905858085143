import React, { useState } from "react";
import { Button, Popover, Input, Form } from "antd";
import styled from "styled-components";
import './Styles/Remarks.css';
import { IMAGES } from "../utils/images";
import { useDispatch, useSelector } from "react-redux";
import { addActionRemarks, getActionRemarksList } from "../redux/actionsRemarks/actionsRemarksSlice";
import GENERIC_CONSTANTS from '../Constants/generic';
import {Loader} from './Loader';
import {ErrorComponent} from './ErrorComponent'
import { setIsRemarksAdded } from "../redux/topFilter/topFilterSlice";
import { validateAction } from "../utils/helpers";

const RemarksTitle = styled.div`
  border-bottom: 1px solid #EAECF0;
  display:flex;
  padding-left:16px;
  padding-right:16px;
  justify-content:space-between;
`;

export const CustomPopoverContent = ({actionId, onClose ,tab}) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const actionRemarksData = useSelector(state=>state.actionRemarks.actionRemarks.data);
  const actionRemarksIsLoading  = useSelector(state=>state.actionRemarks.actionRemarks.loading);
  const actionRemarksError  = useSelector(state=>state.actionRemarks.actionRemarks.error);
  const [remarkValid,setRemarkValid] = useState("");
  const onFinish = (values) => {
    if(!validateAction(values.remarkText)){
      setRemarkValid(GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.ERROR);
      return;
    }
    dispatch(addActionRemarks({actionId,...values}));
    dispatch(setIsRemarksAdded(true));
    form.resetFields();
    onClose();
  };
  return (
    <div className="action-remark-container" style={{minWidth:"350px"}}>
      <RemarksTitle className="actoin-remarks-title">
        <p>{actionRemarksData?.title || 'Remarks'}</p>
        <p onClick={()=>{
          onClose();
          setRemarkValid(GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.SUCCESS);
          form.resetFields();
        }}>Close</p> 
      </RemarksTitle>
      {actionRemarksIsLoading && <div style={{paddingBottom:'60px'}}><Loader/></div>}
      {!actionRemarksIsLoading && actionRemarksError && <div style={{padding:"80px"}}><ErrorComponent error={actionRemarksError}/></div>}
      {!actionRemarksIsLoading && !actionRemarksError  && <div className="action-remark-content">
        {!actionRemarksData.data?.length && 
        <div className="action_table_remarks_item">
            <p>No Remarks Added</p>
          </div>}
        {actionRemarksData?.data?.map((remark,i) => (
          <div className="action_table_remarks_item" key={i}>
            <p>{remark?.Col0?.assignedBy}</p>
            <p>{remark?.Col0?.text}</p>
          </div>
        ))}
      </div>}
      {tab === GENERIC_CONSTANTS.ACTION_TABS.OPEN  && <div style={{ display: "flex",justifyContent:"space-between",padding:"12px"}}>
        <Form 
          onFinish={onFinish}
          form={form}
          className="add-remark-form">
          <Form.Item
            name="remarkText"
            placeholder="Write a remark..."
            validateStatus={remarkValid}
            help={remarkValid === GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.ERROR? `${GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.ACTION_DESCRIPTION}`:''}
          >
          <Input   
            onChange={(e)=>{
              if(validateAction(e.target.value)){
                setRemarkValid(GENERIC_CONSTANTS.TOAST_MESSAGES.VALIDATION.SUCCESS);
                }
            }}  
            className="add-remark-input" 
            placeholder="Write a remark..."  />
          </Form.Item>
          <Form.Item>
            <Button  
              className="add-remark-button" 
              htmlType="submit"
              ><img src={IMAGES.ICONS.SEND_ICON} alt="SEND_ICON"  width={20} height={20}/>
            </Button>
          </Form.Item>
        </Form>
      </div>}
      
    </div>
  );
};
const CustomPopover = ({actionId,tab}) => { 
  
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (isVisible) => {
    setVisible(isVisible);
  };

  const dispatch = useDispatch();
  const handleClickRemarks = ()=>{
      dispatch(getActionRemarksList(actionId));
  }
  return (
    <div className="action_table_remarks_popover">
      <Popover
      content = {<div><CustomPopoverContent tab = {tab} actionId = {actionId} 
      onClose={() => {
        setVisible(false);
      }
      } /></div>}
      trigger = "click"
      placement = "leftTop"
      open = {visible}
      onOpenChange={handleVisibleChange}
      arrow = {false}
      >
     
      <div className="action_table_remarks_btn"   onClick={handleClickRemarks}>
          <img src={IMAGES.ICONS.MESSAGE_SQUARE} alt={IMAGES.ALT_TEXT.MESSAGE_SQUARE} />
          <span>Open Remarks</span>
        </div>
      </Popover>
    </div>
    
  );
};

export default CustomPopover;
