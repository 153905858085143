import React from 'react'
import { IMAGES } from "../utils/images";

function ModificationIndicator() {
  const modificationIndicatorStyle = {
    width: "8px",
    height: "8px", 
    marginRight: "6px" 
  }
  return (
    <div style={{paddingBottom:"4px"}}>
      <img
        src={IMAGES.ICONS.ELLIPSE}
        alt={"ellipse"}
        style={modificationIndicatorStyle}
      />
    </div>
  )
}

export default ModificationIndicator
