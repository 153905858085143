import { createSlice, combineReducers } from "@reduxjs/toolkit";
import { createFetchReducer } from "../../utils/reduxUtility";
import GENERIC_CONSTANTS from '../../Constants/generic';

const initialState = {
  data: {},
  loading: false,
  error: null,
  loaded: false,
  selectedFilter: [],
  isUserUpdateFilter:false
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    ...createFetchReducer("getFilterCategory"),
    selectFilter: (state, action) => {
      console.log(action.payload);
      return {...state,data:{
        ...state.data,
        filterTypeList:action.payload
      }}
    },
    resetFilter: (state) => {
      return {
        ...state,
        data: {
          ...state.data,
          filterTypeList: state.data.filterTypeList.map((group) => {
            return {
              ...group,
              filterList: group?.filterList?.map((item) => {
                if (item.selected) {
                  return { ...item, selected: false };
                }
                return item;
              }),
            };
          }),
        },
      };
    },
    updateSelectedFilters: (state, action) => {
      if (action.payload === GENERIC_CONSTANTS.BUTTON_TYPES.RESET) {
        return { ...state, selectedFilter: [] };
      }
      const selectedFilters = state.data?.filterTypeList?.flatMap((item) => {
        const filterList = item?.filterList?.filter((filter) => filter.selected);
        return filterList?.length > 0 ? { ...item, filterList } : [];
      });
      return { ...state, selectedFilter: selectedFilters };
    },
    resetMetricsSummary: (state) => {
      return initialState;
    },
    setIsUserUpdateFilter:(state,action)=>{
      return {...state,isUserUpdateFilter:action.payload}
    }
  },
});

export const {
  getFilterCategory,
  getFilterCategorySuccess,
  getFilterCategoryFailure,

  selectFilter,
  resetFilterCategory,
  resetFilter,
  updateSelectedFilters,
  setIsUserUpdateFilter
} = filterSlice.actions;

export default combineReducers({
  filterCategory: filterSlice.reducer,
});
