import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getActionRemarksList = (id) => {
	return axiosInstance.get(apiPaths.getRemraksList, {
        params: { id }
    });
}
const addActionRemarks = (payload) => {
	return axiosInstance.post(apiPaths.addActionRemarks,payload);
}

export default {
  getActionRemarksList,
  addActionRemarks
};

