import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getHiringDeepdive = (payload) => {
	return axiosInstance.post(apiPaths.getDeepDiveHiring,payload);
};

export default {
	getHiringDeepdive
};

