import React from "react";
import { Table } from "antd";
import styled from "styled-components";
import GENERIC_CONSTANTS from "../../Constants/generic";
import "./Styles/ActionTab.css";
import { ErrorComponent } from "../../components/ErrorComponent";
import { actionTableColumnRender,dataWrapper } from "../../utils/helpers";
import { DataNotAvailable } from "../../components/DataNotAvailable";
import { Loader } from "../../components/Loader";
import { SummaryViewText } from "../../components/SummaryViewText";
import { useGetData } from "../../utils/hooks/useGetData";

const CustomStyledTable = styled(Table)`
  .ant-table-tbody tr .padding-zero {
    padding: 0;
  }
`;

const ActionTable = ({tab}) => {

  const { data, error, isLoading } = useGetData(GENERIC_CONSTANTS.REDUCER_NAMES.ACTIONS_TABLE);
  const {columns,data:actionData} = data ?? {};

  let tableName = (tab === '1')? GENERIC_CONSTANTS.ACTION_TABS.OPEN:GENERIC_CONSTANTS.ACTION_TABS.COMPLETED;
  return (
    <div className="action_container">
      <div className="summary_text">
        <SummaryViewText text = {tab === '1' ?GENERIC_CONSTANTS.SUB_TEXTS.ACTION_OPEN:GENERIC_CONSTANTS.SUB_TEXTS.ACTION_CLOSE} />
      </div>
      {error && <ErrorComponent error={error} />}
      {!error && (
        <CustomStyledTable
          bordered = {false}
          className="action_table"
          dataSource={dataWrapper(actionData,tableName)}
          columns={actionTableColumnRender(
            columns || [],
            tableName
          )}
          align="top"
          pagination={false}
          loading={{
            spinning: isLoading,
            indicator: <Loader />, // Use your custom loader component here
          }}
          locale={{ emptyText: isLoading?null:<div style={{minHeight:"220px"}}><DataNotAvailable/></div> }}
        />
      )}
    </div>
  );
};

export default ActionTable;