import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
  editFlag:false,
  editId:null,
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    ...createFetchReducer('getActionsList'),
    ...createFetchReducer('addAction'),
    ...createFetchReducer('editAction'),
    ...createFetchReducer('markComplete'),
    ...createFetchReducer('deleteAction'),
    ...createFetchReducer('getRemarksList'),
    resetActionsList: (state) => {
      return initialState;
    },
    updateEditFlag: (state, action) => {
      state.editId = action.payload.id;
      state.editFlag = action.payload.flag;
    },
  },
});

export const {
  getActionsList,
  getActionsListSuccess,
  getActionsListFailure,

  addAction,
  addActionSuccess,
  addActionFailure,
  
  editAction,
  editActionSuccess,
  editActionFailure,

  markComplete,
  markCompleteSuccess,
  markCompleteFailure,

  deleteAction,
  deleteActionSuccess,
  deleteActionFailure,

  getRemarksList,
  getRemarksListSuccess,
  getRemarksListFailure,

  updateEditFlag,
  resetActionsList,

} = actionsSlice.actions;

export default combineReducers({
  actions: actionsSlice.reducer
});
