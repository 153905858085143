import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getAttritionDeepdive = (payload) => {
	console.log(`payload=${JSON.stringify(payload)}`);
	return axiosInstance.post(apiPaths.getDeepDiveAttrition,payload);
};

export default {
	getAttritionDeepdive
};

