// src/sagas/index.js
import { all } from 'redux-saga/effects';
import metricsSaga from './teamMetrics/metricsSaga';
import attritionSaga from './attrition/attritionSaga';
import topFilterSaga from './topFilter/topFilterSaga';
import actionsSaga from './actions/actionsSaga';
import hiringSaga from './hiring/hiringSaga';
import structureSaga from './structure/structureSaga';
import actionRemarksSaga from './actionsRemarks/actionsRemarksSaga';
import actionPlansSaga from './actionPlans/actionPlansSaga';
import healthSaga from './teamHealth/healthSaga';
import trendSaga from './trend/trendSaga';
import filterSaga from './filter/filterSaga';

export default function* combinedSagas() {
  yield all([
    metricsSaga(),
    healthSaga(),
    trendSaga(),
    attritionSaga(),
    hiringSaga(),
    structureSaga(),
    topFilterSaga(),
    actionsSaga(),
    actionRemarksSaga(),
    actionPlansSaga(),
    filterSaga(),
  ]);
}
