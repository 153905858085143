import axios from "axios";
import Constants from "./constants";

// Create an instance of Axios
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: Constants.API_BASE_URL,
  timeout: Constants.API_TIMEOUT,
  headers: {
    ...Constants.HEADERS
  },
});

// Add request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can modify the request config here (e.g., add headers)
    // For example, adding an authorization header for authentication
    // config.headers.Authorization = `Bearer ${yourAuthToken}`;

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response data here
    return response;
  },
  (error) => {
    // Handle response error
    // For example, redirect to login page on 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      // Redirect to login page
      window.location.href = `${Constants.API_BASE_URL}/login`;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
