import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getActionPlansList,
  getActionPlansListSuccess,
  getActionPlansListFailure,

} from './actionPlansSlice';
import API from './api';

function* fetchActionPlansListAsync() {
  try {
    const response = yield call(API.getActionPlansList,'');
    yield put(getActionPlansListSuccess(response.data));
  } catch (error) {
    yield put(getActionPlansListFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* actionPlansSaga() {
  yield takeEvery(getActionPlansList.type, fetchActionPlansListAsync);
}
