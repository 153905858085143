import React from "react";
import { Table } from "antd";
import styled from "styled-components";
import GENERIC_CONSTANTS from "../../../Constants/generic";
import { AttritionChartItems, sortTableColumnsByKey } from "../../../utils/helpers";
import { CustomTab } from "../../../utils/StyledComponents";
import './Styles/Attrition.css';
import { Loader } from "../../../components/Loader";
import { DataNotAvailable } from "../../../components/DataNotAvailable";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { useGetDeepDiveData } from "../../../utils/hooks/useGetDeepDiveData";

const StyledTable = styled(Table)`
  .ant-table-thead tr th {
    background-color: ${GENERIC_CONSTANTS.COLORS.WHITE};
    border-top: 1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM};
  }
`;
const Attrition = () => {
  
  const {data,error,isLoading} = useGetDeepDiveData(GENERIC_CONSTANTS.REDUCER_NAMES.ATTRITION_DEEPDIVE);
  const {seperationReason:seperationTableData}  = data;

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };
  
  const sortedColumns = sortTableColumnsByKey(seperationTableData?.columns);
  return (
    <>
      <div className = "attrition-chart-container box-shadow">
        <p className="chart-title">Attrition By</p>
        <CustomTab className="attrition-chart-tab" defaultActiveKey="1" items={AttritionChartItems}  />
      </div>
      
      <StyledTable
        bordered={false}
        className="attrition_separation_reason_table"
        rowClassName={getRowClassName}
        dataSource={seperationTableData?.data}
        columns={sortedColumns?.map((col,index) => ({
          ...col,
          dataIndex:col.key,
          width:index === 0 ?"50%":"10%",
          title: () => <div className="attrition_separation_reason_table_head">{col.title}</div>,
        }))}
        pagination={false}
        title={()=><><div>{seperationTableData?.title || "Separation Reasons"}</div>
        <p className="attrition_separation_reason_table_subtitle">{seperationTableData?.levelTwoTitle}</p></>}
        loading={{
          spinning: isLoading,
          indicator: <Loader />, // Use your custom loader component here
        }}
        locale={{
          emptyText: isLoading
            ? null
            : error
              ? <div style={{ minHeight: "220px" }}><ErrorComponent error={error} /></div>
              : <div style={{ minHeight: "220px" }}><DataNotAvailable /></div>
        }}
      />
    </>
  );
};

export default  Attrition;