import React, { useState } from "react";
import { Button, Popover, Modal } from "antd";
import styled from "styled-components";
import { IMAGES } from "../utils/images";
import "./Styles/ActionControlButton.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteAction,markComplete,updateEditFlag} from "../redux/actions/actionsSlice";
import GENERICS_CONSTANTS from ".././Constants/generic";
import { actionControlOptions } from "../utils/constants";

const ActionButton = styled(Button)`
  margin-bottom: 8px;
  background-color:transparent;
  box-shadow: 0 0 0 0;
`;

const CustomPopoverContent = ({ id, onClose,controllables }) => {

  const dispatch = useDispatch();
  const { topFilterPayload } = useSelector(state => state.topFilter.topFilter);
  const actionData = {
    actionId:id
 }
  const {span,...payloadWithoutSpan} = topFilterPayload;
  const handleButtonClick = (value) => {
    switch (value) {
      case GENERICS_CONSTANTS.ACTION_CONTROL.DELETE:
        if(!controllables.deletable){
            return;
        }
        Modal.confirm({
          title: GENERICS_CONSTANTS.ACTION_CONTROL.CONFIRMATION,
          content: GENERICS_CONSTANTS.TOAST_MESSAGES.DELETE_CONFIRM,
          okButtonProps: { className: 'custom-ok-button' }, // Custom CSS class for OK button
          cancelButtonProps: { className: 'custom-cancel-button' }, // Custom CSS class for Cancel button
    
          onOk() {
            // Dispatch delete action if confirmed
            dispatch(deleteAction({ actionData,...payloadWithoutSpan,status:GENERICS_CONSTANTS.ACTION_TABS.OPEN}));
          },
        });
        break;

      case GENERICS_CONSTANTS.ACTION_CONTROL.EDIT:
        if(!controllables.editable){
          return;
      }
        dispatch(updateEditFlag({ flag: true, id: id }));
        break;

      case GENERICS_CONSTANTS.ACTION_CONTROL.COMPELETE:
        if(!controllables.completable){
          return;
        }
          dispatch(markComplete({ actionData,...payloadWithoutSpan,status:GENERICS_CONSTANTS.ACTION_TABS.OPEN}));
          break;
      default:
        break;
    }
    onClose(); // Close the popover after handling the click
  };

  return (
    <div className="action_control_menu">
      {actionControlOptions.map((item) => (
        <div key={item.key} className={`action_control_menu_item` }>
          <div  className={
            (item.text === GENERICS_CONSTANTS.ACTION_CONTROL.DELETE && !controllables.deletable ||
             item.text === GENERICS_CONSTANTS.ACTION_CONTROL.EDIT && !controllables.editable ||
             item.text === GENERICS_CONSTANTS.ACTION_CONTROL.COMPELETE && !controllables.completable
            )?"disable":"null"
          } onClick={() => handleButtonClick(item.text)}>
            <img src={item.icon} alt={item.alt} />
            <p>{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

function ActionControlButton({ id ,controllables}) {

  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (isVisible) => {
    setVisible(isVisible);
  };

  return (
    <div className="action_control_popover">
      <Popover
        content={
          <CustomPopoverContent controllables = {controllables} id={id} onClose={() => setVisible(false)} />
        }
        trigger="click"
        placement="leftTop"
        open={visible}
        arrow={false}
        onOpenChange={handleVisibleChange}
      >
        <ActionButton type="default" className="table_action_button">
          <img src={IMAGES.ICONS.DOTS_VERTICAL} alt="dots-vertical" />
        </ActionButton>
      </Popover>
    </div>
  );
}

export default ActionControlButton;
