import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
};

const hiringSlice = createSlice({
  name: 'hiring',
  initialState,
  reducers: {
    ...createFetchReducer('getHiringDeepdive'),
    resetMetricsSummary: (state) => {
      return initialState;
    },
  },
});

export const {
  getHiringDeepdive,
  getHiringDeepdiveSuccess,
  getHiringDeepdiveFailure,
  resetHiringDeepdive,
} = hiringSlice.actions;

export default combineReducers({
  hiringDeepdive: hiringSlice.reducer
});
