import React from 'react'
import GENERIC_CONSTANTS from "../Constants/generic";
export const DataNotAvailable = () => {
  return (
    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}>
      <div style={{ padding: "0px"}}>
        <h1 style={{ padding: "0px", margin: "0px", textAlign: "center", color: "#6941c6" }}>{GENERIC_CONSTANTS.TOAST_MESSAGES.DATA_NOT_AVAILABLE}</h1>
      </div>
    </div>
  );
};