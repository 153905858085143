import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
};

const healthSummarySlice = createSlice({
  name: 'healthSummary',
  initialState,
  reducers: {
    ...createFetchReducer('getHealthSummary'),
    ...createFetchReducer('getReporteeHealthSummary'),
    resetHealthSummary: (state) => {
      return initialState;
    },
}

});

export const {

  getHealthSummary,
  getHealthSummarySuccess,
  getHealthSummaryFailure,

  getReporteeHealthSummary,
  getReporteeHealthSummarySuccess,
  getReporteeHealthSummaryFailure,
  resetHealthSummary,
 
} = healthSummarySlice.actions;

export default combineReducers({
  healthSummary: healthSummarySlice.reducer
});
