import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
  editFlag:false,
  editId:null
};

const actionRemarksSlice = createSlice({
  name: 'actionRemarks',
  initialState,
  reducers: {
    ...createFetchReducer('getActionRemarksList'),
    ...createFetchReducer('addActionRemarks'),
    resetRemarksList: (state) => {
      return initialState;
    }
  },
});

export const {
  getActionRemarksList,
  getActionRemarksListSuccess,
  getActionRemarksListFailure,

  addActionRemarks,
  addActionRemarksSuccess,
  addActionRemarksFailure,
} = actionRemarksSlice.actions;

export default combineReducers({
  actionRemarks: actionRemarksSlice.reducer
});
