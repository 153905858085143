import { IMAGES } from "./images";
import GENERIC_CONSTANTS from "../Constants/generic";

const getHostUrl = () => {
  let baseUrl = `${window.location.protocol}//`;
  baseUrl += window.location.hostname;
  console.log("process.env.NODE_ENV:::", process.env.NODE_ENV);
  if (process.env.NODE_ENV === "development") baseUrl += `:8080`;

  return baseUrl;
};

const getBaseUrl = () => {
  const url = getHostUrl();
  return `${url}/ui/apis`;
};

const dateTime = {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

export const actionControlOptions = [
  {
    key: "1",
    text: GENERIC_CONSTANTS.ACTION_CONTROL.EDIT,
    icon: IMAGES.ICONS.PENCIL,
    alt: GENERIC_CONSTANTS.ACTION_CONTROL.EDIT,
  },
  {
    key: "2",
    text: GENERIC_CONSTANTS.ACTION_CONTROL.COMPELETE,
    icon: IMAGES.ICONS.CHECK_SQUARE,
    alt: GENERIC_CONSTANTS.ACTION_CONTROL.COMPELETE,
  },
  {
    key: "3",
    text: GENERIC_CONSTANTS.ACTION_CONTROL.DELETE,
    icon: IMAGES.ICONS.TRASH,
    alt: GENERIC_CONSTANTS.ACTION_CONTROL.DELETE,
  },
];

export default Object.freeze({
  API_BASE_URL: getBaseUrl(),
  HEADERS: {
    "content-type": "application/json",
    Accept: "application/json",
  },
  API_TIMEOUT: 120000,
  UNAUTHORISED_CODE: 401,
  UNAUTHORISED_EXCEPTION_URLS: [],
  DATE_TIME_FORMAT: new Intl.DateTimeFormat("en-GB", dateTime),
  EMAIL_REGEX:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  PAGE_SIZE: 20,
  ROUTES: {
    DATA_SUMMARY:"/ui/",
    TEAM_HEALTH: "/ui/deep-dive/team-health",
    TEAM_METRICS: "/ui/deep-dive/team-metrics",
    TEAM_CAPABILITIES: "/ui/deep-dive/team-competencies",
  },
});
