import React from "react";
import { Row } from "antd";
import GENERIC_CONSTANTS from "../../Constants/generic";
import "./Styles/TeamMetrics.css";
import { SummaryViewText } from "../../components/SummaryViewText";
import { useGetData } from "../../utils/hooks/useGetData";
import { DataSummaryColumn } from "../../components/DataSummaryColumn";

const TeamMetrics = () => {

  const { data, error, isLoading } = useGetData(GENERIC_CONSTANTS.REDUCER_NAMES.TEAM_METRICS_SUMMARY);
  const { attritions: attritionData,hirings: hiringData,structures: structureData,summaryToolTips} = data?.metrics ?? {};

  const teamMetricsColumns = [
    {
      key: "1",
      title: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.STRUCTURE.TITLE,
      tabName:GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.STRUCTURE.TAB_NAME,
      data: structureData,
      tooltip:summaryToolTips?.[GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.STRUCTURE.TAB_NAME]
    },
    {
      key: "2",
      title: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.ATTRITION.TITLE,
      tabName:GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.ATTRITION.TAB_NAME,
      data: attritionData,
      tooltip:summaryToolTips?.[GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.ATTRITION.TAB_NAME]
    },
    {
      key: "3",
      title: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.HIRING.TITLE,
      tabName: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.HIRING.TAB_NAME,
      data: hiringData,
      tooltip:summaryToolTips?.[GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.HIRING.TAB_NAME]
    },
  ];
  return (
    <>
      <SummaryViewText text={GENERIC_CONSTANTS.SUB_TEXTS.TEAM_METRICS} />
      <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
        {teamMetricsColumns.map((item) => (
          <DataSummaryColumn
            key={item.key}
            error={error}
            isLoading={isLoading}
            data={item.data}
            title={item.title}
            tabName={item.tabName}
            tooltip={item.tooltip}
          />
        ))}
      </Row>
    </>
  );
};

export default TeamMetrics;