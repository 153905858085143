import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getAttritionDeepdive,
  getAttritionDeepdiveSuccess,
  getAttritionDeepdiveFailure,
} from './attritionSlice';
import API from './api';

function* fetchAttritionDeepdiveAsync(action) {
  try {
    const response = yield call(API.getAttritionDeepdive, action.payload);
    yield put(getAttritionDeepdiveSuccess(response.data));
  } catch (error) {
    yield put(getAttritionDeepdiveFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* attritionSaga() {
  yield takeEvery(getAttritionDeepdive.type, fetchAttritionDeepdiveAsync);
}
