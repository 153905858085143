import { Col, Row, Button, Tabs,Tooltip } from "antd";
import styled from "styled-components";
import GENERIC_CONSTANTS from "../Constants/generic";

export const CardColumnHeading = styled(Col)`

  margin-bottom:5px;
  margin-top:0px;
  padding-top: 5px;
  margin-left:0px;
  overflow: hidden;
  color:#667085;
  
`;

export const CardColumn = styled(Col)`
  padding-right: 0px;
  padding-top:0px;
  width:31%;
  
  .inner-box {
    border-radius: 8px;
    border-width: 0 1px 1px 1px; /* top, right, bottom, left */
    border-style: none solid solid solid;
    border-color:  ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM};
    box-shadow: 0 2px 6px 0 rgba(208, 213, 221, 0.5), 0 4px 10px 0 rgba(208, 213, 221, 0.3);
    max-height:600px;


  }
  .inner-header {
    border-top: 1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM};
    border-radius: 8px 8px 0 0;
  }
  .inner-header h4 {
    margin-left: 20px;
    font-weight: 500;
  }
  .deep-dive {
    margin-top: 14px;
  }
  .card-content {
  }

  .content-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: ${GENERIC_CONSTANTS.COLORS.CARD_CONTENT_TITILE};
    padding: 20px;
  }
  .plain-list {
    border-top: 1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM};
  }
  .plain-list h4 {
    padding-left: 20px;
    padding-bottom: 10px;
  }
`;

export const StyledList = styled.ul`
  
  list-style: none;
  padding-left:20px;
  color: #667085;
`;

export const StyledListItem = styled.li`

  margin-bottom: 0px;
  overflow: hidden;
  line-height:30px;
`;

export const IndexColumn = styled.span`
  display: block;
  width: auto;
  vertical-align: top;
  margin-left:2px;
  
`;

export const TextColumn = styled.span`
 
  display: block;
  width: 74%;
  margin-left: 7px;
`;

export const TextBold = styled.span`
 
  font-weight:600;
  color: hsl(210, 15%, 30%);
`;

export const ButtonColumn = styled.div`
  display: inline-block;
  width: 15%;
  vertical-align: top;
`;

export const prepareColumnHeader = (title,tooltip,tabName, clickFn,isLoading) => {
  return (
    <Row className="inner-header" style={{ borderTopRadius: "16px" }}>
      <Col span={15}>
        <Tooltip title = {tooltip?.trim()} color="#F9F5FF">
          <h4
          style={{
            opacity: isLoading ? 0.5 : 1,
          }}
          className="attrition_column_header_title"
        >
          {title}
        </h4>
        </Tooltip>
       
      </Col>
      <Col span={8} style={{ textAlign: "right" }}>
        <div className="deep-dive">
          <Button
            disabled={isLoading}
            className=" attrition_column_header_button"
            onClick={() => clickFn(tabName)}
          >
            Deep Dive
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export const CustomTab = styled(Tabs)`
  .ant-tabs-nav {
    
    border-bottom: none;
    padding-bottom:10px;
   
  }

  .ant-tabs-nav .ant-tabs-tab {

    color: #667085;
    font-size:14px;
    border-radius:6px;
    font-weight: 500;
    padding:8px 12px 8px 12px;
    margin-top:12px;
    max-width:148px;
    height:36px;
    place-content:center;
    margin-right:6px;
    margin-left:20px;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    background-color: ${GENERIC_CONSTANTS.COLORS.PRIMARY_COLOR};
    border-radius: 8px;
    display:grid;
    place-content:center;
  }

  .ant-tabs-ink-bar {
    display: none;
    
  }

  .ant-tabs-content-holder {
    border-left: none;
  }

  .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6941c6;
  }
`;

export const Footer = styled.div`
  margin-top: 0px;
  background: ${GENERIC_CONSTANTS.COLORS.FOOTER_GREY};
  padding: 0px;
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0px;
  margin: 0px;
`;