import React, { useEffect } from "react";
import { Table } from "antd";
import styled from "styled-components";
import GENERIC_CONSTANTS from "../../../Constants/generic";
import { generateRandomData } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../utils/constants"

const StyledTable = styled(Table)`
  .ant-table-thead tr th {
    background-color: ${GENERIC_CONSTANTS.COLORS.WHITE};
    border-top: 1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM};
  }
`;
const TeamCompetencies = () => {

  const navigate = useNavigate();
  useEffect(()=>{
    navigate(`${CONSTANTS.ROUTES.TEAM_CAPABILITIES}/${GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES.TEAM_COMPETENCIES.TAB_NAME}`);
  },[]);
  const columns = [
    {
      title: "Connect survery",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "2023",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "2021",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "2020",
      dataIndex: "email",
      key: "email",
    },
  ];

  const getTitle = (title, desc) => {
    return (
      <div>
        <p>
          <strong>{title}</strong>
        </p>
        <p>{desc}</p>
      </div>
    );
  };

  // Generate random data
  const data = generateRandomData(4);
  return (
    <>
      <StyledTable
        bordered
        style={{ marginTop: 50 }}
        dataSource={data}
        columns={columns.map((col) => ({
          ...col,
          title: () => <div>{col.title}</div>,
        }))}
        pagination={false}
        title={() => getTitle("Feedback on Input", "")}
      />
    </>
  );
  
};
export default TeamCompetencies;
