import React from "react";
import GENERIC_CONSTANTS from "../../../Constants/generic";
import { CustomTab } from "../../../utils/StyledComponents";
import AOPFillRate from "./AOPFillRate";
import { Row,Col } from "antd";
import "./styles/chartStyle.css";
import HeadCount from "./HeadCount";
import GenderDiversity from "./GenderDiversity";
const StructureTab = () => {
   const items = [
    {
      key: "1",
      label: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.STRUCTURE_CHART_TABS.AOP.TITLE,
      children: <AOPFillRate/>,
    },
    {
      key: "2",
      label: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.STRUCTURE_CHART_TABS.HEADCOUNT.TITLE,
      children: <HeadCount/>
    },
    {
      key: "3",
      label: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.STRUCTURE_CHART_TABS.GENDER.TITLE,
      children: <GenderDiversity/>
    },
  ];

  
  return (
    <>
    <div className="chartContainer box-shadow">
    <Row className="StyledRow" gutter={0}>
     <Col xs={24}>
     <div className="tabTitle">Structure By</div>
     </Col>
     <Col xs={24} >
     <CustomTab defaultActiveKey="1" items={items} />
     </Col>
   </Row>
   </div>
   </>
    );
};
export default StructureTab;
