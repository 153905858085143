import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import './Styles/ErrorComponent.css' // Import the LoadingOutlin

export const Loader = () => {
  return (
    <div className='loader_container'>
      <Spin spinning={true} indicator={<LoadingOutlined style={{fontSize: "32px", color: "#6941c6" }} />} />
    </div>
  );
};
