import React from "react";
import { Row, Col, Table, Alert } from "antd";
import styled from "styled-components";
import GENERIC_CONSTANTS from "../../../Constants/generic";
import StatusIndicator from "../../../components/StatusIndicator";
import { Loader } from "../../../components/Loader";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { DataNotAvailable } from "../../../components/DataNotAvailable";
import { useGetDeepDiveData } from "../../../utils/hooks/useGetDeepDiveData";
import './Styles/TeamHealth.css';
import { getAlertMsg } from "../../../utils/helpers";

const StyledTable = styled(Table)`
  .ant-table-thead tr th {
    background-color: ${GENERIC_CONSTANTS.COLORS.WHITE};
    border-top: 1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM};
  }
`;
const Trends = () => {

  const {data,error,isLoading} = useGetDeepDiveData(GENERIC_CONSTANTS.REDUCER_NAMES.TREND_DEEPDIVE);
  const {columns:trendTableColumn,data:trendTableData}  = data;
  
  const columnsData = [
    {
      title:trendTableColumn?.[0]?.title,
      dataIndex:trendTableColumn?.[0]?.key,
      key:trendTableColumn?.[0]?.title,
      width:'60%',
      render: (text, record, index) => {
        return(record?.Col0?.displayText)
      },
    },
    {
      title:trendTableColumn?.[1]?.title,
      dataIndex:trendTableColumn?.[1]?.key,
      key:trendTableColumn?.[1]?.key,
      width:'13%',
      render: (text, record) => {
        return (
          <div style={{textAlign:"center",display:"grid",placeContent:"center"}}>
            {record?.Col1?.displayText && <p>{record?.Col1?.displayText}</p>}
            {record?.Col1?.statisticsVal && (
              <StatusIndicator
               
                percentage={record?.Col1?.statisticsVal}
                trend={record?.Col1?.statisticsTrendInd}
                tab={GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME}
              />
            )}
          </div>
        );
      },
    },
    {
      title:trendTableColumn?.[2]?.title,
      dataIndex:trendTableColumn?.[2]?.title,
      key:trendTableColumn?.[2]?.title,
      width:'13%',
      render: (text, record) => {
        return (
          <div style={{textAlign:"center",display:"grid",placeContent:"center"}}>
            {record?.Col2?.displayText && <p>{record?.Col2?.displayText}</p>}
            {record?.Col2?.statisticsVal && (
              <StatusIndicator
               
                percentage={record?.Col2?.statisticsVal}
                trend={record?.Col2?.statisticsTrendInd}
                tab={GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME}
              />
            )}
          </div>
        );
      },
    },
    {
      title:trendTableColumn?.[3]?.title,
      dataIndex:trendTableColumn?.[3]?.title,
      key:trendTableColumn?.[3]?.title,
      width:'13%',
      render: (text, record) => {
        return (
          <div style={{textAlign:"center",display:"grid",placeContent:"center"}}>
            {record?.Col3?.displayText && <p>{record?.Col3?.displayText}</p>}
            {record?.Col3?.statisticsVal && (
              <StatusIndicator
               
                percentage={record?.Col3?.statisticsVal}
                trend={record?.Col3?.statisticsTrendInd}
                tab={GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={16} style={{marginRight:"16px"}}>
        <Col span={24} >
          <Alert  style={{boxShadow: "0px 2px 6px 0 rgba(208, 213, 221, 0.25)"}} message={getAlertMsg()} type="success" closable />
          <StyledTable
            className="connect_survey_table"
            bordered
            style={{ marginTop: 24 }}
            dataSource={trendTableData}
            columns={columnsData}
            pagination={false}
            loading={{
              spinning: isLoading,
              indicator: <Loader />, // Use your custom loader component here
            }}
            locale={{
              emptyText: isLoading
                ? null
                : error
                  ? <div style={{ minHeight: "220px" }}><ErrorComponent error={error} /></div>
                  : <div style={{ minHeight: "220px" }}><DataNotAvailable /></div>
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Trends;