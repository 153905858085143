import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getTopFilter = () => {
	return axiosInstance.get(apiPaths.getTopFilter);
};

const getManagerReporteeList = (payload) => {
	return axiosInstance.post(apiPaths.getManagerReporteeList,payload);
};

const getHrbpReporteeList = (payload) => {
	return axiosInstance.post(apiPaths.getHrbpReporteeList,payload);
};

export default {
	getTopFilter,
	getManagerReporteeList,
	getHrbpReporteeList
};

