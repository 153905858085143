import React from 'react'
import { Row } from "antd";
import { CardColumnHeading } from '../utils/StyledComponents';
import "./Styles/SummaryViewText.css"
export const SummaryViewText = ({text}) => {
  return (
    
    <Row  className = "summmaryViewText">
      <CardColumnHeading>
      <p style={{fontWeight:'550'}}>{text}</p>
    </CardColumnHeading> 
    </Row>  
  )
}
