const Constants = Object.freeze({
  TOAST_MESSAGES: {
    DATA_NOT_AVAILABLE:
      "No Data Available!",
    COMMON_ERROR_MESSAGE:
    "Error occurred while processing the request. Try after sometime.",
    LOGIN_ERROR_MESSAGE :"Login in-progress...",
    DELETE_CONFIRM:"Will be deleted permanently. Are you sure?",
    SUCCESS :{
      EDIT:"This action is updated successfully!",
      ADD:"A new action is added successfully!",
      MARK: "This action is now marked as complete!",
      DELETE:"This action is deleted successfully!",
      REMARK:"A new remark is added successfully!"
    },
    VALIDATION:{
      TOPFILTER_MANAGER:"Please enter only alphabetic characters",
      ACTION_DESCRIPTION:"Min. 2 chars ; Max. 550 chars; characters not allowed : < > * ^ ! \\ | { }",
      ERROR:"error",
      SUCCESS:"success"
    }
    
  },
  SUB_TEXTS:{
      TEAM_METRICS: "Summary of your metrics under Structure, Attrition & Hiring",
      TEAM_HEALTH : "Summary of your Connect Survey data",
      ACTION_OPEN : "List of Actions - Open for you",
      ACTION_CLOSE: "List of Completed Actions"
  },
  TOOLTIPS:{
      DATA_SUMMARY:"Your Team Data - Only FTE Employees"
  },
  COLORS: {
    WHITE: "#fff",
    FOOTER_GREY: "#F9FAFB",
    TRANSPARENT: "transparent",
    PLAIN_TABLE_BOTTOM_BORDER: "#eaecf0",
    CARD_BORDER_BOTTOM: "#d0d5dd",
    CARD_CONTENT_TITILE: "#667085",
    LIGHT_GREEN: "#ECFDF3",
    LIGHT_RED: "#FEF3F2",
    DARK_GREEN: "#12B76A",
    DARK_RED: "#F04438",
    DARK_GREY:"#EEEEEE",
    PRIMARY_COLOR: "#F9F5FF",
    SEGMENTED_COLOR: "#344054",
    NUMBER_HEXCODE:'#475467'
  },
  LABELS: {
    DATA: "DATA",
    ACTIONS: "ACTIONS",
  },
  BUTTON_TYPES: {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    DEFAULT: "default",
    APPLY: "apply",
    RESET: "reset"
  },
  DATA_SUMMARY_TABS: {
    TAB_NAME :"Summary",
    TEAM_HEALTH: {
      TAB_TITILE: "Team Health",
      TAB_KEY:"1",
      TRENDS: {
        TITLE: "Trend : Manager Effectiveness",
        TAB_NAME: "trends",
      },
      HIGHLIGHTS: {
        TITLE: "Highlights",
        TAB_NAME: "highlights",
      },
      GAPS: {
        TITLE: "Gaps",
        TAB_NAME: "gaps",
      },
    },
    TEAM_METRICS: {
      TAB_TITILE: "Team Metrics",
      TAB_KEY:"2",
      ATTRITION: {
        TITLE: "Attrition",
        TAB_NAME: "attritions",
      },
      HIRING: {
        TITLE: "Hiring",
        TAB_NAME: "hirings",
      },
      STRUCTURE: {
        TITLE: "Structure",
        TAB_NAME: "structures",
      },
    },
    TEAM_CAPABILITIES: {
      TAB_TITILE: "Team Capabilities",
      TAB_KEY:"3",
      PERFORMANCE_TRENDS: {
        TITLE: "Performance Trends",
        TAB_NAME: "performance_trends",
      },
      TEAM_COMPETENCIES: {
        TITLE: "Team Competencies",
        TAB_NAME: "team_competency",
      },
      SELF_COMPETENCIES: {
        TITLE: "Self Competencies",
        TAB_NAME: "self_competency",
      },
    },
  },
  DEEP_DIVE_TABS: {
    TAB_NAME:"DeepDive",
    TEAM_METRICS: {
      ATTRITION_CHART_TABS: {
        GRADE: {
          TITLE: "Grade",
          TAB_NAME: "Grade",
        },
        PERFOMANCE: {
          TITLE: "Performance",
          TAB_NAME: "Performance",
        },
        GENDER: {
          TITLE: "Gender",
          TAB_NAME: "Gender",
        },
        ATTRITION_TYPE: {
          TITLE: "Attrition Type",
          TAB_NAME:"Attrition Type",
        }
      },
     STRUCTURE_CHART_TABS: {
      AOP: {
          TITLE: "AOP Snapshot",
          TAB_NAME: "AOP Snapshot",
        },
        HEADCOUNT: {
          TITLE: "Headcount Change",
          TAB_NAME: "Headcount Change",
        },
        GENDER: {
          TITLE: "Gender Diversity",
          TAB_NAME: "Gender Diversity",
        },
        PYRAMID:{
          TITLE: "Organization Structure - Pyramid",
          TAB_NAME: "Organization Structure - Pyramid",
        }
      },
      HIRING_CHART_TABS:{

        HIRES: {
          TITLE: "External Hires by Month",
          TAB_NAME: "External Hires by Month",
        },

        GENDER: {
          TITLE: "Gender Diversity",
          TAB_NAME: "Gender Diversity",
        },
      }
    }
  },
  ACTION_TABS:{
    OPEN:"Open",
    COMPLETED:"Completed"
  },
  TOP_FILTER:{
      SELECTOR:{
        QUARTER:"timeline",
        REPORTEE:"reportee",
        SPAN:"span"
      }
  },
  LIST_LENGTH:5,
  ACTION_CONTROL:{

      TITLE:{
          ADD :"Add an action",
          EDIT:"Edit an action"
      },
      CREATE:"Create",
      EDIT:"Edit action",
      REMARK:"Add a remark",
      COMPELETE:"Mark as complete",
      DELETE:"Delete",
      CONFIRMATION:"Delete Action",
  },
  DATE:{
    FORMAT:"YYYY-MM-DD"
  },
  TREND:{
    UP:"UP",
    DOWN:"DOWN",
    NEUTRAL:"NEUTRAL"
  },
  ROLES:{
    MANAGER:'Manager',
    HRBP:'HRBP'
  },
  CHARTS:{
    ATTRITION:{
      BY_ATTRITION:"attritionType",
      BY_GRADE:"grade",
      BY_GENDER:"gender",
      BY_PERFORMANCE:"performance"
    },
    STRUCTURE:{
      BY_AOP:"aop",
      BY_HEADCOUNT:"headCountChange",
      BY_GENDER:"gender",
    },
    HIRING:{
      BY_HIRES_MONTH:"gender"
    }
  },
   REDUCER_NAMES : {
    TEAM_METRICS_SUMMARY:"metricsSummary",
    TEAM_HEALTH_SUMMARY: "healthSummary",
    ATTRITION_DEEPDIVE: 'attritionDeepdive',
    HIRING_DEEPDIVE: 'hiringDeepdive',
    STRUCTURE_DEEPDIVE: 'structureDeepdive',
    TREND_DEEPDIVE:'trendDeepdive',
    ACTIONS_TABLE:'actions'
  },
  REGEX_PATTERN:{
    ACTION_DESCRIPTION:/^(?=.{2,550}$)(?!.*[<>*\^!\\|{}]).*$/
  }

});

export default Constants;
