// src/reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import headerReducer from './header/headerSlice';
import metricsReducer from './teamMetrics/metricsSlice';
import healthReducer from './teamHealth/healthSlice';
import attritionDeepdiveReducer from './attrition/attritionSlice'
import hiringDeepdiveReducer from './hiring/hiringSlice'
import structureDeepdiveReducer from './structure/structureSlice'
import topFilterReducer from './topFilter/topFilterSlice';
import actionsReducer from './actions/actionsSlice';
import actionPlansreducer from './actionPlans/actionPlansSlice'
import actionsRemarksSlice from './actionsRemarks/actionsRemarksSlice';
import trendDeepdiveReducer from './trend/trendSlice';
import filterReducer from './filter/filterSlice';

const rootReducer = combineReducers({
  header: headerReducer,
  metricsSummary: metricsReducer,
  healthSummary : healthReducer,
  trendDeepdive:trendDeepdiveReducer,
  attritionDeepdive:attritionDeepdiveReducer,
  hiringDeepdive:hiringDeepdiveReducer,
  structureDeepdive:structureDeepdiveReducer,
  topFilter:topFilterReducer,
  actions:actionsReducer,
  actionRemarks:actionsRemarksSlice,
  actionPlans:actionPlansreducer,
  filter:filterReducer
});

export default rootReducer;
