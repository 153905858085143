import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col,Image, Button } from "antd";
import Trends from "./TeamHealth/Trends";
import Attrition from "./TeamMetrics/Attrition";
import Structure from "./TeamMetrics/Structure";
import Performance from "./TeamCapabilities/Performance";
import SelfCompetencies from "./TeamCapabilities/SelfCompetencies";
import TeamCompetencies from "./TeamCapabilities/TeamCompetencies";
import { IMAGES } from "../../utils/images";
import { useNavigate, useParams } from "react-router-dom";
import Filters from "./Filters";
import "./Styles/index.css"
import {Hiring} from "./TeamMetrics/Hiring";
import { useDispatch, useSelector } from "react-redux";
import {getReporteeHealthSummary} from "../../redux/teamHealth/healthSlice";
import { setDataSummaryTabKey } from "../../redux/topFilter/topFilterSlice";
import CONSTANTS from "../../utils/constants";
import { getReporteeMetricsSummary } from "../../redux/teamMetrics/metricsSlice";
import GENERIC_CONSTANTS from '../../Constants/generic'
import { SelectedFilterSumary } from "../../components/SelectedFilterSumary";

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height:480px;

`;

const StyledListItem = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 12px;
  margin-bottom:8px;
  width:170px;

  &:hover {
    background-color: #f9f5ff;
    border-radius:6px;
  }

  &.selected {
    background-color: #f9f5ff;
    border-radius:6px;
    color: #6941c6;
  }
   &.not-selected {
    
    color: #667085;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

  }

  &:last-child {
    border-bottom: none;
  }

  &.group {
    color: #475467;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 8px;
    cursor:auto;
  }
  &.group:hover{
    background-color: transparent;
  }

  @media (max-width: 768px) {
    padding: 8px 10px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 480px) {
    padding: 8px 8px; /* Further adjust padding for even smaller screens */
  }
`;

const data = [
  
  {
    group: "Team Metrics",
    items: [
      {
        TITLE: "Structure",
        TAB_NAME:GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.STRUCTURE.TAB_NAME,
      },
      {
        TITLE: "Attrition",
        TAB_NAME: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.ATTRITION.TAB_NAME,
      },
      {
        TITLE: "Hiring",
        TAB_NAME: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.HIRING.TAB_NAME,
      }
    ],
  },
  {
    group: "Team Health",
    items: [
      {
        TITLE: "Connect survey",
        TAB_NAME: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TAB_NAME,
      },
    ],
  },
  // {
  //   group: "Team Capabilities",
  //   items: [
  //     {
  //       TITLE: "Performance Trends",
  //       TAB_NAME: "performance_trends",
  //     },
  //     {
  //       TITLE: "Self Competency",
  //       TAB_NAME: "self_competency",
  //     },
  //     {
  //       TITLE: "Team Competency",
  //       TAB_NAME: "team_competency",
  //     },
  //   ],
  // },
];

const UlLiWithOptgroup = () => {
  const { tabName } = useParams();
  const isLoading = useSelector(state=>state.topFilter.topFilter.loading);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(tabName);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dataSummaryTabKey = useSelector(
    (state) => state.topFilter.topFilter.dataSummaryTabKey
  );
  const { topFilterPayload } = useSelector(state => state.topFilter.topFilter);
  const {selectedFilter} = useSelector(state=>state.filter.filterCategory);
  const displayFilters = () => {
    setIsModalVisible(true);
  };

  const closeFilter = () => {
    setIsModalVisible(false);
  };

  const navigate = useNavigate();

  const handleChangeTab = (tab, group) => {
    !isLoading && setSelectedTab(tab);
    switch (group) {
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.TAB_TITILE:
        dispatch(setDataSummaryTabKey(GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.TAB_KEY));
        break;
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TAB_TITILE:
        dispatch(setDataSummaryTabKey(GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TAB_KEY));
        break;
      default:
        dispatch(setDataSummaryTabKey(GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TAB_KEY));
    }
  };
  const onBack = () => {
    if(topFilterPayload?.reportee && !isLoading && dataSummaryTabKey === GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TAB_KEY){
      dispatch(getReporteeHealthSummary(topFilterPayload));
    }
    else if(topFilterPayload?.reportee && !isLoading && dataSummaryTabKey === GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.TAB_KEY){
      dispatch(getReporteeMetricsSummary(topFilterPayload));
    }
    !isLoading && navigate(CONSTANTS.ROUTES.DATA_SUMMARY);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TAB_NAME:
        return <Trends />;
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.ATTRITION.TAB_NAME:
        return <Attrition />;
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.HIRING.TAB_NAME:
        return <Hiring/>;
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.STRUCTURE.TAB_NAME:
        return <Structure />;
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES.PERFORMANCE_TRENDS:
        return <Performance />;
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES.SELF_COMPETENCIES:
        return <SelfCompetencies />;
      case GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES.TEAM_COMPETENCIES:
        return <TeamCompetencies />;
      default:
        return <Trends />;
    }
  };

  return (
    <Row>
      <Col xxl={4} xl={4} lg={6} md={8} sm={11} xs={12}>
        <div>
          <Col>
            <div className="backButton_container">
              <Button disabled={isLoading}>
                <Image
                  src={IMAGES.ICONS.BACK_ICON}
                  alt="back_button_icon"
                  preview={false}
                  width={20}
                  height={20}
                  onClick={onBack}
                />
              </Button>
              <p>Data Deep Dive</p>
            </div>
          </Col>
        </div>
        <StyledList>
          {data.map(({ group, items }, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <StyledListItem className="group">{group}</StyledListItem>
              {items.map((item, itemIndex) => (
                <StyledListItem
                  style={{cursor: isLoading?"not-allowed":"pointer"}}
                  key={itemIndex}
                  onClick={() => { handleChangeTab(item.TAB_NAME,group)}}
                  className={
                    selectedTab === item.TAB_NAME ? "selected" : "not-selected"
                  }
                >
                  <span className="deepdive-tab-title">{item.TITLE}</span>
                </StyledListItem>
              ))}
            </React.Fragment>
          ))}
        </StyledList>
      </Col>
      <Col xxl={20} xl={20} lg={18} md={16} sm={13} xs={12}>
        <Row style={{ marginBottom: "22px", marginRight: "22px" }}>
          <Col span={12}>{
            tabName !== GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TAB_NAME &&
            <Button
              className="deepdive-addfilter"
              onClick={() => displayFilters()}
              disabled = {isLoading}
              icon={
                <img
                  src = {IMAGES.ICONS.FILTER_ICON}
                  alt = {IMAGES.ALT_TEXT.FILTER_ICON}
                  width = {16.7}
                  height = {15.8}
                />
              }
            >
              Add Filters
            </Button>
          }
            
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {/* <Button
              className="deepdive-addfilter"
              icon={
                <img
                  src = {IMAGES.ICONS.DOWNLOAD_ICON}
                  alt = {IMAGES.ALT_TEXT.DOWNLOAD_ICON}
                  width = {15}
                  height = {15}
                  style = {{ color: "#6941c6 !important" }}
                />
              }
            >
              Raw Data
            </Button> */}
          </Col>
        </Row>
        {
        selectedFilter?.length !== 0 && 
        tabName !== GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TAB_NAME &&
        <Row>
          <SelectedFilterSumary/>
        </Row>
        }
        {renderContent()}
      </Col>
      {isModalVisible ? (
        <Filters closeFilter={closeFilter} displayFilter={isModalVisible} />
      ) : null}
    </Row>
  );
};

export default UlLiWithOptgroup;
