import React, { useEffect,useRef } from 'react';
import * as echarts from 'echarts';
import "./styles/chartStyle.css";
import {
  chartTooltip,
  checkDataAvailability,
  chartLegends,
  chartXaxis,
  chartYaxis,
  chartGrid,
  chartSeriesData,
  resizeChart,
} from "../../../utils/helpers";
import { DataNotAvailable } from '../../DataNotAvailable';
import { ErrorComponent } from '../../ErrorComponent';
import { Loader } from '../../Loader';
import { useGetData } from '../../../utils/hooks/useGetData';
import GENERIC_CONSTANTS from '../../../Constants/generic';

const HeadCount = () => {

  const { data, error, isLoading } = useGetData(GENERIC_CONSTANTS.REDUCER_NAMES.STRUCTURE_DEEPDIVE);
  const { headCountChange:chartData } = data;
  const chartRef = useRef(null);

  useEffect(() => {
    let chart;
    if (chartRef.current !== null ) {
      chart = echarts.init(chartRef.current);
    }
    const resizeHandler = () => chart?.resize();
    window.addEventListener("resize", resizeHandler);
    var options = {
      grid: {...chartGrid,left:"3.4%"},
      xAxis: {...chartXaxis,type: chartData?.xaxis?.type || "category",data: chartData?.xaxis?.data || [],},
      yAxis :{...chartYaxis,type: chartData?.yaxis?.type || "value", data: chartData?.yaxis?.data || [],},
      legend:{...chartLegends,data: chartData?.legend?.data, bottom: 0,left: 12,},
      series : chartSeriesData (chartData,["#0047BB","#C98BDB","#FF6900"],["insideTop","top",[22,-30]],'ad',"#ffff"),
      tooltip:chartTooltip,
    };
    // Set the option to the chart
    checkDataAvailability(chartData) ? chart.setOption(options) : chart.clear();    
    return () => {
      window.removeEventListener("resize",resizeHandler);
      chart?.dispose();
    };
  }, [chartData]);

  useEffect(() => {
    const resizeObserver = resizeChart(chartRef,echarts);
    return () => {
      resizeObserver.disconnect();
    };
  }, []); 

  return (
    <>
      {isLoading && <Loader />}
      {error && !isLoading && <ErrorComponent error={error} />}
      {!checkDataAvailability(chartData) && !error && !isLoading && (
        <DataNotAvailable />
      )}
      <div className="chartContent" ref={chartRef}></div>
    </>
  );
};

export default HeadCount;