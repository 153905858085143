import React from 'react';
import { StyledList, StyledListItem, IndexColumn, TextColumn} from "../utils/StyledComponents"

const PlainNumberedList = ({data}) => {
  const items = data ? data : [
    'My manager connects with me formally or informally on an ongoing basis.',
    'My manager connects with me formally or informally on an ongoing basis.',
    'My manager connects with me formally or informally on an ongoing basis.',
  ];

  return (
    <StyledList>
      {items.map((item, index) => (
        <StyledListItem key={index}>
          <IndexColumn>{index + 1}.</IndexColumn>
          <TextColumn>{item}</TextColumn>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default PlainNumberedList;
