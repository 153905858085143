import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getActionsList = (payload) => {
	return axiosInstance.post(apiPaths.getActionsList,payload);
};
const createAction = (payload) => {
	return axiosInstance.post(apiPaths.createAction,payload);
};
const editAction = (payload) => {
	return axiosInstance.post(apiPaths.editAction,payload);
};
const markComplete = (payload) => {
	return axiosInstance.post(apiPaths.markComplete,payload);
};
const deleteAction = (payload) => {
	return axiosInstance.post(apiPaths.deleteAction,payload);
};
const getRemarksList = (payload) => {
	return axiosInstance.post(apiPaths.getRemraksList,payload);
}

export default {
  getActionsList,
  createAction,
  editAction,
  deleteAction,
  markComplete,
  getRemarksList
};

