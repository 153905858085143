import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
};

const trendSlice = createSlice({
  name: 'trend',
  initialState,
  reducers: {
    ...createFetchReducer('getTrendDeepdive'),
    resetMetricsSummary: (state) => {
      return initialState;
    },
  },
});

export const {
  getTrendDeepdive,
  getTrendDeepdiveSuccess,
  getTrendDeepdiveFailure,
  resetTrendDeepdive,
} = trendSlice.actions;

export default combineReducers({
  trendDeepdive: trendSlice.reducer
});
