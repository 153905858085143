// Router.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/About/About";
import { Layout } from "antd";
import AppHeader from "./components/Header";
import AppFooter from "./components/Footer";
import DataSummary from "./pages/DataSummary";
import DeepDive from "./pages/DeepDive/index"

import { layoutStyle, contentRouteStyle, contentStyle } from "./utils/styles";

const { Content } = Layout;

const AppRouter = () => {
  return (
    
      <div className="App">
        <Layout style={layoutStyle}>
          <AppHeader />
          <Content style={contentStyle}>
            <div style={contentRouteStyle}>
              <Routes>
                <Route path="/" element={<DataSummary />} />
                <Route path="/about" element={<About />} />
                <Route path="/deep-dive/team-metrics/:tabName" element={<DeepDive />} />
                <Route path="/deep-dive/team-health/:tabName" element={<DeepDive />} />
                <Route path="/deep-dive/team-competencies/:tabName" element={<DeepDive />} />
              </Routes>
            </div>
          </Content>
          <AppFooter />
        </Layout>
      </div>
   
  );
};

export default AppRouter;
