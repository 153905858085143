import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate} from "react-router-dom";
import {
  getHrbpReporteeList,
  getManagerReporteeList,
  setIsTimeLineChanged,
  setIsReporteeChanged,
  setIsSpanChanged,
  setActionTableKeyIsChanged,
  setIsRemarksAdded,
} from "../../redux/topFilter/topFilterSlice";
import { getAttritionDeepdive } from "../../redux/attrition/attritionSlice";
import { getStructureDeepdive } from "../../redux/structure/structureSlice";
import { getHiringDeepdive } from "../../redux/hiring/hiringSlice";
import { getTrendDeepdive } from "../../redux/trend/trendSlice";
import { getActionsList } from "../../redux/actions/actionsSlice";
import GENERIC_CONSTANTS from "../../Constants/generic";
import { setIsUserUpdateFilter } from "../../redux/filter/filterSlice";

export const useGetDeepDiveData = (deepDiveKey) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialRender = useRef(true);

  const data = useSelector((state) => state[deepDiveKey]?.[deepDiveKey]?.data);
  const deepDiveError = useSelector((state) => state[deepDiveKey]?.[deepDiveKey]?.error);
  const actionRemarksData = useSelector( (state) => state.actionRemarks.actionRemarks.data);

  const {
    hrbpToggler,
    isTimeLineChanged,
    isReporteeChanged,
    isSpanChanged,
    topFilterPayload,
    actionTableKey,
    isActionTableKeyChanged,
    isRemarksAdded,
    error: topFilterError,
  } = useSelector((state) => state.topFilter.topFilter);

  const {selectedFilter:dataFilters,isUserUpdateFilter} = useSelector((state) => state.filter.filterCategory);
  const error = deepDiveError || topFilterError;
  const isLoading = useSelector((state) => state[deepDiveKey]?.[deepDiveKey]?.loading);

  let getDeepDiveData, tabName, payload;
  let getMangerHRBPPayload = {timeline:topFilterPayload.timeline,span:topFilterPayload.span};
  if (deepDiveKey === GENERIC_CONSTANTS.REDUCER_NAMES.ATTRITION_DEEPDIVE) {
    getDeepDiveData = getAttritionDeepdive;
    payload = { ...topFilterPayload, filterTypeList: dataFilters };
    tabName =
      GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.ATTRITION.TAB_NAME;
  } else if (
    deepDiveKey === GENERIC_CONSTANTS.REDUCER_NAMES.STRUCTURE_DEEPDIVE
  ) {
    getDeepDiveData = getStructureDeepdive;
    payload = { ...topFilterPayload, filterTypeList: dataFilters };
    tabName =
      GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.STRUCTURE.TAB_NAME;
  } else if (deepDiveKey === GENERIC_CONSTANTS.REDUCER_NAMES.HIRING_DEEPDIVE) {
    getDeepDiveData = getHiringDeepdive;
    payload = { ...topFilterPayload, filterTypeList: dataFilters };
    tabName = GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.HIRING.TAB_NAME;
  } else if (deepDiveKey === GENERIC_CONSTANTS.REDUCER_NAMES.TREND_DEEPDIVE) {
    getDeepDiveData = getTrendDeepdive;
    payload = { ...topFilterPayload };
    tabName = GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TAB_NAME;
  }else if(deepDiveKey === "actions"){
    getDeepDiveData = getActionsList;
    const {span,...payloadWithoutSpan} = topFilterPayload;
    payload = { ...payloadWithoutSpan,status:actionTableKey};
    tabName = null;
  }
  
  useEffect(() => {
    tabName && navigate(`/ui/deep-dive/team-metrics/${tabName}`);
  },[]);

  useEffect(() => {
    if (isTimeLineChanged && topFilterPayload?.timeline?.timelineText) {
      dispatch(
        hrbpToggler
          ? getHrbpReporteeList(getMangerHRBPPayload)
          : getManagerReporteeList(getMangerHRBPPayload)
      );
      dispatch(setIsTimeLineChanged(false));
    } else if (isReporteeChanged &&  topFilterPayload?.reportee?.reporteeName) {
      dispatch(getDeepDiveData(payload));
      dispatch(setIsReporteeChanged(false));
    } else if (
      initialRender?.current &&
      topFilterPayload?.reportee?.reporteeName
    ) {
      dispatch(getDeepDiveData(payload));
      initialRender.current = false;
    } else if (isUserUpdateFilter) {
      dispatch(getDeepDiveData(payload));
      dispatch(setIsUserUpdateFilter(false));
    } else if (isSpanChanged && topFilterPayload?.span?.spanText) {
      dispatch(getDeepDiveData(payload));
      dispatch(setIsSpanChanged(false));
    } else if(isActionTableKeyChanged){
      dispatch(getDeepDiveData(payload));
      dispatch(setActionTableKeyIsChanged(false));
    } else if(isRemarksAdded){
      dispatch(getDeepDiveData(payload));
      dispatch(setIsRemarksAdded(false));
    }
    // isReporteeChanged will trigger the useEffect to fetch the deep dive data when quarter changes in deep dive page.
    // This flage set to true in topfilter component when reporteeList get updated as part of initial fetch or toggle
  }, [
    topFilterPayload?.reportee?.reporteeName,
    topFilterPayload?.span?.spanText,
    topFilterPayload?.timeline?.timelineText,
    dataFilters,
    actionTableKey,
    actionRemarksData,
    isReporteeChanged
  ]);
 
  return {
    data,
    error,
    isLoading
  };
};
