import React from "react";
import { Row, Col, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./Styles/SelectedFilterSummary.css";
import {
  resetFilter,
  selectFilter,
  setIsUserUpdateFilter,
  updateSelectedFilters,
} from "../redux/filter/filterSlice";
import GENERIC_CONSTANTS from "../Constants/generic";
import { CloseOutlined } from "@ant-design/icons";

export const SelectedFilterSumary = () => {
  const { selectedFilter } = useSelector(
    (state) => state.filter.filterCategory
  );
  const dataFilters =
    useSelector((state) => state.filter.filterCategory.data?.filterTypeList) ||
    [];
  const dispatch = useDispatch();
  const handleClose = (filterTypeName) => {
    const updatedDataFilters = dataFilters.map((filterType) => {
      if (filterType?.filterTypeName === filterTypeName) {
        return {
          ...filterType,
          filterList: filterType.filterList.map((list) => {
            return {
              ...list,
              selected: false,
            };
          }),
        };
      }
      return filterType;
    });
    dispatch(selectFilter(updatedDataFilters));
    dispatch(updateSelectedFilters(GENERIC_CONSTANTS.BUTTON_TYPES.APPLY));
    dispatch(setIsUserUpdateFilter(true));
  };

  const handleReset = () => {
    dispatch(updateSelectedFilters(GENERIC_CONSTANTS.BUTTON_TYPES.RESET));
    dispatch(resetFilter());
    dispatch(setIsUserUpdateFilter(true));
  };
  return (
    <Row className="filter-summary-container">
      <Col span={20}>
        {selectedFilter.map((item) => {
          return (
            <Tag
              key={item?.filterTypeName}
              className="filter-summary-tag"
              icon={<CloseOutlined />}
              bordered={false}
              onClick={() => handleClose(item?.filterTypeName)}
            >
              {item?.filterTypeName}
            </Tag>
          );
        })}
      </Col>

      <Col span={4} style={{textAlign:"right"}}>
        <div>
          <span className="filter-summary-reset" onClick={handleReset}>
            Reset Filters
          </span>
        </div>
      </Col>
    </Row>
  );
};
