import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getHiringDeepdive,
  getHiringDeepdiveSuccess,
  getHiringDeepdiveFailure,
} from './hiringSlice';
import API from './api';

function* fetchHiringDeepdiveAsync(action) {
  try {
    const response = yield call(API.getHiringDeepdive, action.payload);
    yield put(getHiringDeepdiveSuccess(response.data));
  } catch (error) {
    yield put(getHiringDeepdiveFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* hiringSaga() {
  yield takeEvery(getHiringDeepdive.type, fetchHiringDeepdiveAsync);
}
