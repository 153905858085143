import React, { useEffect,useRef } from "react";
import * as echarts from "echarts";
import GENERIC_CONSTANTS from "../../../Constants/generic";
import { Col, Row } from "antd";
import { DataNotAvailable } from "../../DataNotAvailable";
import { ErrorComponent } from "../../ErrorComponent";
import { checkDataAvailability, resizeChart } from "../../../utils/helpers";
import { Loader } from "../../Loader";
import {chartTooltip} from "../../../utils/helpers";
import "./styles/chartStyle.css";
import { useGetData } from "../../../utils/hooks/useGetData";

function AttritionChartByGrade() {
 
  const { data, error, isLoading } = useGetData(GENERIC_CONSTANTS.REDUCER_NAMES.ATTRITION_DEEPDIVE);
  const {grade:chartData} = data;
  const chartRef = useRef(null);

  useEffect(() => {
    // Get the DOM element to render the chart
    let chart;
    if (chartRef.current !== null ) {
      chart = echarts.init(chartRef.current);
    }
    const resizeHandler = () => chart?.resize();
    window.addEventListener("resize", resizeHandler);
    const options = {
      grid: {
        left: "5%",
        top: "6%",
        bottom: "32%",
        right: "3%",
        // Adjust the left padding to move the chart to the left
        // Other grid configurations...
      },
      legend: {
        data: chartData?.legend?.data.length?chartData?.legend?.data:['Grade'],
        itemStyle: {},
        bottom: 23, // Adjust this value to position the legend
        left: 20,
        itemGap: 10,
        itemWidth: 10,
        itemHeight: 10,
      },
      tooltip: chartTooltip,
      xAxis: {
        type: chartData?.xaxis?.type || "category",
        data: chartData?.xaxis?.data || [],
        axisTick: { 
          // Remove axisTick
          show: false
        },
        axisLabel: {
          color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
          interval: 0,
          rotate: 0,
          margin: 40,
        },
      },
      yAxis: {
        type: chartData?.yaxis?.type || "value",
        data: chartData?.yaxis?.data || [],
        axisLabel: {
          color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
          formatter: "{value}%",
          margin:10
        },
      },

      series: [
        {
          name: chartData?.series[0]?.name || "Manager Level",
          data: chartData?.series[0]?.data || [],
          type: chartData?.series[0]?.type || "line",
          emphasis: {
            focus: 'series'
          },
          itemStyle: {
            normal: {
              color: "#C98BDB",
              barBorderRadius: [8, 8, 0, 0],
              label: {
                show: true,
                color:GENERIC_CONSTANTS.COLORS.NUMBER_HEXCODE,
                position: [15,-15],
                formatter: function(params){
                  return `{border|${chartData?.series?.[0].values[params.dataIndex]}\t(${params.value}%)}`
                },
                rich: {
                  border: {
                  borderColor: "#EAECF0", // Border color for the text
                  borderWidth: 0, // Border width for the text
                  borderRadius: 4,
                  backgroundColor: "transparent",
                  padding: 6,
                  },
                 },

              },
            },
          },
        },

        {
          name: chartData?.series[1]?.name || "Functional Level",
          data: chartData?.series[1]?.data || [],
          type: chartData?.series[1]?.type || "line",
          emphasis: {
            focus: 'series'
          },
          itemStyle: {
            normal: {
              color:  "#ff0000ff",
              barBorderRadius: [8, 8, 0, 0],
              label: {
                show: true,
                color:'#ff0000ff',
                position: [-15,15],
                formatter: function(params){
                  return `{border|${chartData?.series?.[1].values[params.dataIndex]}\t(${params.value}%)}`
                },
                rich: {
                  border: {
                  borderColor: "#EAECF0", // Border color for the text
                  borderWidth: 0, // Border width for the text
                  borderRadius: 4,
                  backgroundColor: "transparent",
                  padding: 6,
                  },
                 },
              },
            },
          },
          barGap: "0%",
        },
      ],
    };
    // Set the chart options
    console.log(chartData?.series);

    checkDataAvailability(chartData) ? chart.setOption(options) : chart.clear();
    return () => {
      window.removeEventListener("resize", resizeHandler);
      chart?.dispose();
    };
  }, [chartData]);

  useEffect(() => {
    const resizeObserver =  resizeChart(chartRef,echarts);
    return () => {
      resizeObserver.disconnect();
    };
  }, []); 
  
  return (
    <>
      <Row>
        <Col span={24}>
          {isLoading && <Loader />}
          {error && !isLoading && <ErrorComponent error={error} />}
          {!checkDataAvailability(chartData) && !error && !isLoading && (
            <DataNotAvailable />
          )}

          <div
            className="chart_container"
            style={{
              opacity: isLoading ? 0.5 : 1,
              transition: "opacity 0.5s ease",
            }}
            ref={chartRef}
          ></div>
        </Col>
      </Row>
    </>
  );
}

export default AttritionChartByGrade;
