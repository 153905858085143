import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getActionPlansList = () => {
	return axiosInstance.get(apiPaths.getActionPlansList);
};

export default {
  getActionPlansList,
};

