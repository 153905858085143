import React from "react";
import "./Styles/FunnelBar.css";
import GENERIC_CONSTANTS from "../Constants/generic";

const FunnelBar = ({ data, tab }) => {
  let parentContainerWidth =
    tab === GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME ? 420 : 365;
  return (
    <>
      {data?.length !== 0? (
        <div
          className="funnel-bar-container"
          style={{ width: parentContainerWidth }}
        >
          {data?.map((item, index) => {
            let width =
              tab === GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME
                ? 190 + 2 * item.percentValue
                : 130 + 2 * item.percentValue;
            return (
              <div key={index} style={{ width: width }}>
                {item.displayText}
              </div>
            );
          })}
        </div>
      ):null}
    </>
  );
};

export default FunnelBar;
