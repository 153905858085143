import React, { useEffect, useState } from "react";
import GENERIC_CONSTANTS from "../../../Constants/generic";
import "./Styles/Hiring.css";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Select, Space } from "antd";
import { Col, Row } from "antd";
import { Table } from "antd";
import { CustomTab } from "../../../utils/StyledComponents";
import { funnelSelectionListCreator, sortTableColumnsByKey} from "../../../utils/helpers";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { Loader } from "../../../components/Loader";
import HiresByMonth from "../../../components/Charts/HiringCharts/HiresByMonth";
import FunnelBar from "../../../components/FunnelBar";
import { DataNotAvailable } from "../../../components/DataNotAvailable";
import { useGetDeepDiveData } from "../../../utils/hooks/useGetDeepDiveData";

export const Hiring = () => {

  const {data,error, isLoading} = useGetDeepDiveData(GENERIC_CONSTANTS.REDUCER_NAMES.HIRING_DEEPDIVE);
  const {interviewFunnel}  = data;
  const {interviewStagesData,offersData,title,levelTwoTitle,funnelSelectionListTitle,funnelSelectionList,funnelItems} = interviewFunnel ?? {};
  const {columns:hiringFunnelTableColumn,data:hiringFunnelTableData,footer:hiringFunnelTableFooter} = interviewStagesData ?? {};
  const {columns:hiringOfferTableColumn,data:hiringOfferTableData,footer:hiringOfferTablefooter} = offersData ?? {};
  const [funnel, setFunnel] = useState(null);
  const handleFunnelSelectChange = (value) => {
    setFunnel(value);
  };
  
  useEffect(()=>{
    setFunnel(null);
  },[funnelSelectionList])
  const sortedTable1Columns = sortTableColumnsByKey(hiringFunnelTableColumn);
  const sortedTable2Columns = sortTableColumnsByKey(hiringOfferTableColumn);

  const chartItems = [
    {
      key: "1",
      label:
        GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.HIRING_CHART_TABS.HIRES
          .TITLE,
      children: (
        <HiresByMonth
          chartObj={{ domName: "hiring_gender", title: GENERIC_CONSTANTS.DEEP_DIVE_TABS.TEAM_METRICS.HIRING_CHART_TABS.HIRES
          .TITLE }}
        />
      ),
    },
  ];

  return (
    <>
      <div
       className="box-shadow"
        style={{
          border: `1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM}`,
          borderRadius: "12px",
          paddingLeft: "0px",
          width: "98%",
        }}
      >
        <p className="chart-title">Hiring By</p>
        <CustomTab
          className="hiring-chart-tab"
          defaultActiveKey="1"
          items={chartItems}
        />
      </div>
      
      <div>
        <div
          className="hiring-container box-shadow"
          style={{
            opacity: isLoading ? 0.4 : 1,
            transition: "opacity 0.5s ease",
          }}
        >
          {isLoading && !error &&<div style={{marginTop:"200px"}}>  <Loader /></div>}
          {error && !isLoading && <div style={{marginTop:"900px"}}><ErrorComponent error={error} /></div>}

          {!error && (
            <>
              <div className="hiring-header">
                <div className="hiring-title">{title}</div>
                <p className="hiring-subtitle">{levelTwoTitle}</p>
              </div>

              <div className="hiring-content">
                
                <p style={{ fontWeight: "500" }}>{funnelSelectionListTitle}</p>
                <Select
                  virtual = {false}
                  className="hiring-position-select"
                  options={funnelSelectionListCreator(funnelSelectionList)}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={funnel}
                  onChange={handleFunnelSelectChange}
                  placeholder="Select a position"
                  showSearch
                />

                <Row className="hiring-row">
                
                  <Col xl={10}  lg={24} md={24} style={{ width: "450px", height: "auto"}}>
                    <div style={{width:"450px",height:"auto"}}>
                     { funnelItems?.[funnel] && <FunnelBar data={funnelItems?.[funnel]} tab={GENERIC_CONSTANTS.DEEP_DIVE_TABS.TAB_NAME}/>} 
                    </div>
                  </Col>
                  {/* <Col xl={10} offset={1} lg={24} md={24}>
                    <div style={{ height: "236px", width: "553px" }}>
                      <Table
                        className="hiring-table"
                
                        style={{ marginLeft: "0px", height: "165px" }}
                        columns={sortedTable1Columns?.map((column) => ({
                          ...column,
                          render: (text, record) => {
                            const { text: value, color } = record[column.key];
                            return (
                              <span style={{ color, textAlign: "center" }}>
                                {value}
                              </span>
                            );
                          },
                        }))}
                        dataSource={hiringFunnelTableData}
                        pagination={false}
                       
                      />

                      <div className="note1">
                        *{" "}
                        <span style={{ color: "#ED4437" }}>
                          {hiringFunnelTableFooter?.slice(0, 3)}{" "}
                        </span>{" "}
                        /{" "}
                        <span style={{ color: "#4BA870" }}>
                          {hiringFunnelTableFooter?.slice(4, 9)}
                        </span>{" "}
                        {hiringFunnelTableFooter?.slice(9)}
                      </div>
                    </div>
                  </Col> */}
                </Row>
               
              </div>
            </>
          )}
        </div>
        {/* <div style={{ marginTop: "20px" }}>
          <Row style={{ height: "300px" }}>
            <Col span={16}>
              <div style={{ height: "236px", width: "553px"}}>
                <Table
                  
                  className="hiring-table"
                  bordered
                  style={{ marginLeft: "0px", height: "165px" }}
                  columns={sortedTable2Columns?.map((column) => ({
                    ...column,
                    render: (text, record) => {
                      const { text: value, color } = record[column.key];
                      return <span style={{ color }}>{value}</span>;
                    },
                  }))}
                  dataSource={hiringOfferTableData}
                  pagination={false}
                  loading={{
                    spinning: isLoading,
                    indicator: <Loader />, // Use your custom loader component here
                  }}
                  locale={{
                    emptyText: isLoading
                      ? null
                      : error
                        ? <div style={{ minHeight: "220px" }}><ErrorComponent error={error} /></div>
                        : <div style={{ minHeight: "220px" }}><DataNotAvailable /></div>
                  }}
                />

                {!error && <div className="note1">
                  *{" "}
                  <span style={{ color: "#ED4437" }}>
                    {hiringOfferTablefooter?.slice(0, 3)}{" "}
                  </span>{" "}
                  /{" "}
                  <span style={{ color: "#4BA870" }}>
                    {hiringOfferTablefooter?.slice(4, 9)}
                  </span>{" "}
                  {hiringOfferTablefooter?.slice(9)}
                </div>}
              </div>
            </Col>
          </Row>
        </div> */}

        
      </div>
    </>
  );
};