import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getMetricsSummary,
  getMetricsSummarySuccess,
  getMetricsSummaryFailure,

  getReporteeMetricsSummary,
  getReporteeMetricsSummarySuccess,
  getReporteeMetricsSummaryFailure

} from './metricsSlice';
import API from './api';

function* fetchMetricsSummaryAsync() {
  try {
    const response = yield call(API.getMetricsSummary, '');
    yield put(getMetricsSummarySuccess(response.data));
  } catch (error) {
    yield put(getMetricsSummaryFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

function* fetchReporteeMetricsSummaryAsync(action) {
  try {
    const response = yield call(API.getReporteeMetricsSummary, action.payload);
    yield put(getReporteeMetricsSummarySuccess(response.data));
  } catch (error) {
    yield put(getReporteeMetricsSummaryFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* metricsSaga() {
  yield takeEvery(getMetricsSummary.type, fetchMetricsSummaryAsync);
  yield takeEvery(getReporteeMetricsSummary.type, fetchReporteeMetricsSummaryAsync);
}
