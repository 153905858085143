import React from 'react';
import { Layout } from 'antd';
import TopFilter from './TopFilter'
import { useSelector } from 'react-redux';
import './Styles/Header.css';

const { Header } = Layout;

const AppHeader = () => {

  const {welcomeText} =  useSelector(
    (state) => state.topFilter.topFilter.data
  ) 
  return (
    <Header className='header-container'>
      <div>
        <h1>{welcomeText}</h1>
      </div>
      <TopFilter />
    </Header>
  );
};

export default AppHeader;
