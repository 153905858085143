import React from "react";
import NestedNumberList from "./NestedNumberList";
import StatusIndicator from "./StatusIndicator";
import ModificationIndicator from "./ModificationIndicator";
import { ErrorComponent } from "./ErrorComponent";
import { DataNotAvailable } from "./DataNotAvailable";
import { Loader } from "./Loader";
import FunnelBar from "./FunnelBar";
import { CardColumn, prepareColumnHeader } from "../utils/StyledComponents";
import GENERIC_CONSTANTS from "../Constants/generic";
import CONSTANTS from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'antd';

export const DataSummaryColumn = ({
  error,
  isLoading,
  data,
  title,
  tabName,
  tooltip
}) => {
  const cardTitleStyle = {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "0px",
  };

  const displayTextStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const deepDiveClicked = (tabName) => {
    navigate(`${CONSTANTS.ROUTES.TEAM_METRICS}/${tabName}`);
  };

  const navigate = useNavigate();

  return (
    <CardColumn>
      <div className="inner-box ">
        <div className="inner-box-header">
          <div className="card-sticky">
            {prepareColumnHeader(title,tooltip,tabName, deepDiveClicked, isLoading)}
          </div>
          <div
            className="card-content card_scroll"
            style={{
              opacity: isLoading ? 0.5 : 1,
            }}
          >
            {isLoading && <Loader />}
            {!isLoading && error && <ErrorComponent error={error} />}
            {!isLoading && !error && !data?.length && <DataNotAvailable />}
            {data &&
              data.map((item, index) => (
                <div
                  key={index}
                  style={{
                    borderBottom:
                      index !== data.length - 1
                        ? `1px solid ${GENERIC_CONSTANTS.COLORS.PLAIN_TABLE_BOTTOM_BORDER}`
                        : "none",
                  }}
                >
                  <div className="content-title " style={cardTitleStyle}>
                    <div style={displayTextStyle}>
                      {item?.itemChanged && <ModificationIndicator />}
                      <Tooltip title = {item?.toolTip?.trim()} color="#F9F5FF">
                        <span  className="attrition_item_title margin_left-4">
                        {item?.displayText}
                      </span>{" "}
                      </Tooltip>
                      
                    </div>
                    {item?.statisticsVal && (
                      <div>
                        <StatusIndicator
                          percentage={item?.statisticsVal}
                          trend={item?.statisticsTrendInd}
                          tab={GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TAB_NAME}
                        />
                      </div>
                    )}
                  </div>
                  {item.displayText === "Summary of Hiring Funnel" &&
                    item?.funnelItemList?.length !== 0 && (
                      <div
                        style={{
                          marginLeft: "24px",
                          marginTop: "24px",
                          marginBottom: "24px",
                        }}
                      >
                        <FunnelBar
                          data={item?.funnelItemList}
                          tab={GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TAB_NAME}
                        />
                      </div>
                    )}
                  <NestedNumberList
                    cardItemList={item?.cardItemList || []}
                    titleIndex={index}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </CardColumn>
  );
};