import { useSelector } from "react-redux";

export const useGetData = (reducer) => {

    const data = useSelector(
        (state) => state[reducer]?.[reducer]?.data
      );
    const reducerError = useSelector(
        (state) => state[reducer]?.[reducer]?.error
      );
    const topFilterError = useSelector(
        (state) => state.topFilter.topFilter.error
      );

      const topFilterLoading = useSelector(
        (state) => state.topFilter.topFilter.loading
      );
    
    const error = reducerError || topFilterError;

    const reducerLoading = useSelector(
        (state) => state[reducer]?.[reducer]?.loading
      );

    const isLoading = reducerLoading || topFilterLoading
    return {data,error,isLoading}

}