import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';

const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
};

const attritionSlice = createSlice({
  name: 'attrition',
  initialState,
  reducers: {
    ...createFetchReducer('getAttritionDeepdive'),
    resetMetricsSummary: (state) => {
      return initialState;
    },
  },
});

export const {
  getAttritionDeepdive,
  getAttritionDeepdiveSuccess,
  getAttritionDeepdiveFailure,
  resetAttritionDeepdive,
} = attritionSlice.actions;

export default combineReducers({
  attritionDeepdive: attritionSlice.reducer
});
