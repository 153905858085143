import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getStructureDeepdive = (payload) => {
	return axiosInstance.post(apiPaths.getDeepDiveStructure,payload);
};

export default {
	getStructureDeepdive
};

