import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getFilterCategory = (payload) => {
	return axiosInstance.post(apiPaths.getFilterCategory,payload);
};

export default {
	getFilterCategory
};

