import axiosInstance from '../../utils/axiosInstance';
import apiPaths from '../../utils/apiPaths';

const getTrendDeepDive = (payload) => {
	return axiosInstance.post(apiPaths.getDeepDiveTrend,payload);
};

export default {
	getTrendDeepDive
};

