import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getHealthSummary,
  getHealthSummarySuccess,
  getHealthSummaryFailure,

  getReporteeHealthSummary,
  getReporteeHealthSummarySuccess,
  getReporteeHealthSummaryFailure,

} from './healthSlice';
import API from './api';

function* fetchHealthSummaryAsync() {
  try {
    const response = yield call(API.getHealthSummary, '');
    yield put(getHealthSummarySuccess(response.data));
  } catch (error) {
    yield put(getHealthSummaryFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

function* fetchReporteeHealthSummaryAsync(action) {
  try {
    const response = yield call(API.getReporteeHealthSummary, action.payload);
    yield put(getReporteeHealthSummarySuccess(response.data));
  } catch (error) {
    yield put(getReporteeHealthSummaryFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* healthSaga() {
  yield takeEvery(getHealthSummary.type, fetchHealthSummaryAsync);
  yield takeEvery(getReporteeHealthSummary.type, fetchReporteeHealthSummaryAsync);
}
