import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Checkbox } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { IMAGES } from "../../utils/images";
import { useDispatch, useSelector } from "react-redux";
import "./Styles/Filter.css";
import {
  resetFilter,
  selectFilter,
  setIsUserUpdateFilter,
  updateSelectedFilters,
} from "../../redux/filter/filterSlice";
import GENERIC_CONSTANTS from '../../Constants/generic'
import { DataNotAvailable } from "../../components/DataNotAvailable";
import { ErrorComponent } from "../../components/ErrorComponent";

const FilterDiv = styled.div`
  display: flex;
  justify-content:flex-start;
  gap:16px;
  color:#101828;
  height: auto;
  border: 0.8px solid #eaecf0;
  padding: 15px;
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:hover {
    background-color: #F9F5FF;
  }
  &.selected {
    background-color: red;
  }
`;

const FilterDivTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 85%;
`;

const GroupTitleDiv = styled.div`
  color: #6941c6;
  margin-bottom: 8px;
  .item {
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid #f9f5ff;
    background: #f9f5ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #6941c6;
    margin-right: 10px;
    margin-bottom: 8px;
  }
`;

const FilterComponent = ({ displayFilter, closeFilter }) => {

  const [isModalVisible, setIsModalVisible] = useState(displayFilter);
  const [currentGroup, setCurrentGroupDisplay] = useState();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.filter.filterCategory.data?.filterTypeList) || [];
  const error = useSelector((state) => state.filter.filterCategory.error);
  const [dataFilters,SetDataFilters] = useState([]);

  const handleClose = () =>{
    setIsModalVisible(false);
    closeFilter();
  }
  const handleCancel = () => {
    dispatch(updateSelectedFilters(GENERIC_CONSTANTS.BUTTON_TYPES.RESET));
    dispatch(resetFilter())
    dispatch(setIsUserUpdateFilter(true));
    setIsModalVisible(false);
    closeFilter();
  };
  const handleApplyFilter = ()=>{
    dispatch(selectFilter(dataFilters));
    dispatch(updateSelectedFilters(GENERIC_CONSTANTS.BUTTON_TYPES.APPLY));
    dispatch(setIsUserUpdateFilter(true));
    setIsModalVisible(false);
    closeFilter();
  }
  const onFilterGroupSelect = (filterTypeName) => {
    setCurrentGroupDisplay(filterTypeName);
  };
  const  onSelectCheckBox = (e, filterTypeName, filterName) => {
    SetDataFilters(
      dataFilters.map((filterType) => {
        if (filterType?.filterTypeName === filterTypeName) {
          return {
            ...filterType,
            filterList: filterType.filterList.map((list) => {
              if (list.filterName === filterName) {
                return {
                  ...list,
                  selected: e.target.checked,
                };
              }
              return list;
            }),
          };
        }
        return filterType;
      })
    );
  };

  useEffect(()=>{
    SetDataFilters(data);
    setCurrentGroupDisplay(data?.[0]?.filterTypeName);
  },[])

  const FilterListWithArrow = () => {
    return (
      <div>
        <div>
          {dataFilters?.map((item, index) => {
            return (
              <FilterDiv
                className={item?.filterTypeName === currentGroup ? 'filter-category' : ''}
                key={index}
                onClick={() => onFilterGroupSelect(item.filterTypeName)}
              >
                <FilterDivTitle>
                  {item?.filterList?.filter(
                    (filterNames) => filterNames?.selected === true
                  ).length > 0 && (
                    <img
                      src={IMAGES.ICONS.DOT_GREEN}
                      width={6}
                      height={6}
                      style={{ marginRight: "8px" }}
                      alt={IMAGES.ALT_TEXT.DOT_GREEN}
                    />
                  )}
                  {item?.filterTypeName}
                </FilterDivTitle>
                <div>
                  <ArrowRightOutlined />
                </div>
              </FilterDiv>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        title = " "
        closeIcon = 'Close'
        open={isModalVisible}
        onCancel={handleClose}
        footer={[
          <Button 
            className="btn-reset" 
            key = {GENERIC_CONSTANTS.BUTTON_TYPES.RESET}
            onClick={handleCancel}
            disabled = {dataFilters.length === 0 ?true:false}
          >
           Reset Filters
          </Button>,
          <Button 
            className="btn-apply" 
            key = {GENERIC_CONSTANTS.BUTTON_TYPES.APPLY} 
            onClick={handleApplyFilter}
            disabled = {dataFilters.length === 0 ?true:false}
          >
            Apply Filters
          </Button>,
        ]}
      >
        {error && <ErrorComponent error={error}/>}
        {!error && dataFilters.length === 0 && <DataNotAvailable/>}
        <Row gutter={16}>
          <Col span={11}>
            <FilterListWithArrow />
          </Col>
          <Col span={12}>
            <div>
              <div>
                {dataFilters
                  .filter((group) => group?.filterTypeName === currentGroup)[0]
                  ?.filterList.map((item, index) => {
                    return (
                      <FilterDiv key={`${item}-${index}`}>
                        <div>
                          <Checkbox
                            checked={item?.selected}
                            onChange={(e) =>
                              onSelectCheckBox(
                                e,
                                currentGroup,
                                item?.filterName
                              )
                            }
                          />
                        </div>
                        <div style={{textAlign:'left',overflowWrap:"break-word",placeContent:"center"}}>{item?.filterName}</div>
                      </FilterDiv>
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default FilterComponent;
