import React from "react";
import GENERIC_CONSTANTS from "../../Constants/generic";
import "./Styles/ActionTab.css";
import { CustomTab } from "../../utils/StyledComponents";
import { setActionTableKey, setActionTableKeyIsChanged} from "../../redux/topFilter/topFilterSlice";
import ActionTable from "./ActionTable";
import { useDispatch} from "react-redux";
import { useGetDeepDiveData } from "../../utils/hooks/useGetDeepDiveData";

const CustomTable = () => {

  const {data,isLoading} = useGetDeepDiveData(GENERIC_CONSTANTS.REDUCER_NAMES.ACTIONS_TABLE);
  const {openCount,completedCount} = data || {};
  const dispatch = useDispatch();

  const onChange = (key) => {

    if(key ==='1'){
      dispatch(setActionTableKey(GENERIC_CONSTANTS.ACTION_TABS.OPEN));
    }else{
      dispatch(setActionTableKey(GENERIC_CONSTANTS.ACTION_TABS.COMPLETED));
    }
    dispatch(setActionTableKeyIsChanged(true));
    
  };

  const items = [
    {
      key: "1",
      label: `Open (${openCount || 0}) `,
      children: <ActionTable  tab = "1"/>,
      disabled: isLoading,
    },
    {
      key: "2",
      label: `Completed (${completedCount ||0})`,
      children: <ActionTable tab = "2" />,
      disabled: isLoading
    },
  ];

  return (
    <div className="action_container" style={{ marginLeft: "24px",marginTop:"10px"}}>
      <CustomTab
        className="action_container_tab"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomTable;