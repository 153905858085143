import React, { useEffect } from "react";
import * as echarts from "echarts";
import styled from "styled-components";
import GENERIC_CONSTANTS from "../../Constants/generic";

const BarChartContainer = styled.div`
  border: 1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM};
  .title {
    padding-left: 40px;
    padding-top: 40px;
  }
`;

const StackedBarChartWithBorderRadius = ({chartObj}) => {
  const { domName, title} = chartObj
  useEffect(() => {
    // Get the DOM element to render the chart
    const chartContainer = document.getElementById(domName);

    // Initialize ECharts
    const chart = echarts.init(chartContainer);

    let options = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            areaStyle: {}
          }
        ]
      };

    // Set the chart options
    chart.setOption(options);

    // Cleanup on component unmount
    return () => {
      chart.dispose();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <>
      <BarChartContainer>
        <div className="title">{title}</div>
        <div
          id={domName}
          style={{ width: "100%", height: "400px" }}
        />
      </BarChartContainer>
    </>
  );
};

export default StackedBarChartWithBorderRadius;
