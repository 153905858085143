import React from 'react'
import './Styles/SeemoreButton.css'
function SeeMoreButton({ onClick,title }) {
  return (
    
    <div 
      className='seemore_button_container'
      onClick={onClick}>{title}...
    </div>
  )
}

export default SeeMoreButton
