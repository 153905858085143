import { call, put, takeEvery } from 'redux-saga/effects';
import GENERIC_CONSTANTS from '../../Constants/generic';
import {
  getActionRemarksList,
  getActionRemarksListSuccess,
  getActionRemarksListFailure,

  addActionRemarks,
  addActionRemarksSuccess,
  addActionRemarksFailure,

} from './actionsRemarksSlice';
import API from './api';
import { successMessage ,errorMessage} from '../../utils/helpers';

function* fetchActionRemarksListAsync(action) {
  try {
    const response = yield call(API.getActionRemarksList, action.payload);
    yield put(getActionRemarksListSuccess(response.data));
  } catch (error) {
    yield put(getActionRemarksListFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

function* fetchAddActionRemarksAsync(action) {
  try {
    const response = yield call(API.addActionRemarks, action.payload);
    yield put(addActionRemarksSuccess(response.data));
    successMessage(GENERIC_CONSTANTS.TOAST_MESSAGES.SUCCESS.REMARK);
  } catch (error) {
    yield put(addActionRemarksFailure({status:error.response?.status,message:error.response?.data?.message}));
    errorMessage(error.response?.data?.message);
  }
}

export default function* actionRemarksSaga() {
  yield takeEvery(getActionRemarksList.type, fetchActionRemarksListAsync);
  yield takeEvery(addActionRemarks.type, fetchAddActionRemarksAsync);
}
