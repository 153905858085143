import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getTopFilter,
  getTopFilterSuccess,
  getTopFilterFailure,

  getManagerReporteeList,
  getManagerReporteeListSuccess,
  getManagerReporteeListFailure,
  
  getHrbpReporteeList,
  getHrbpReporteeListSuccess,
  getHrbpReporteeListFailure,

} from "./topFilterSlice";
import API from './api';

function* fetchTopFilterAsync() {
 
  try {
    const response = yield call(API.getTopFilter, '');
    yield put(getTopFilterSuccess(response.data));
  } catch (error) {
    yield put(getTopFilterFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

function* fetchGetManagerReporteeListAsync(action) {
 
  try {
    const response = yield call(API.getManagerReporteeList, action.payload);
    yield put(getManagerReporteeListSuccess(response.data));
  } catch (error) {
    yield put(getManagerReporteeListFailure(error.message));
  }
}

function* fetchGetHrbpReporteeListAsync(action) {
 
  try {
    const response = yield call(API.getHrbpReporteeList, action.payload);
    yield put(getHrbpReporteeListSuccess(response.data));
  } catch (error) {
    yield put(getHrbpReporteeListFailure(error.message));
  }
}
export default function* topFilterSaga() {
  yield takeEvery(getTopFilter.type, fetchTopFilterAsync);
  yield takeEvery(getManagerReporteeList.type, fetchGetManagerReporteeListAsync);
  yield takeEvery(getHrbpReporteeList.type, fetchGetHrbpReporteeListAsync);
}
