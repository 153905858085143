import React from "react";
import { Row } from "antd";
import GENERIC_CONSTANTS from "../../Constants/generic";
import "./Styles/TeamMetrics.css";
import { SummaryViewText } from "../../components/SummaryViewText";
import { useGetData } from "../../utils/hooks/useGetData";
import { DataSummaryColumn } from "../../components/DataSummaryColumn";

const TeamHealth = () => {

  const { data, error, isLoading } = useGetData(GENERIC_CONSTANTS.REDUCER_NAMES.TEAM_HEALTH_SUMMARY);
  const { trends: trendsData,highlights: highlightsData,gaps: gapsData,summaryToolTips} = data?.health ?? {};

  const teamHealthColumns = [
    {
      key: "1",
      title: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TITLE,
      tabName: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TAB_NAME,
      data: trendsData,
      tooltip:summaryToolTips?.[GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TRENDS.TAB_NAME]
    },
    {
      key: "2",
      title: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.HIGHLIGHTS.TITLE,
      tabName:GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.HIGHLIGHTS.TAB_NAME,
      data: highlightsData,
      tooltip:summaryToolTips?.[GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.HIGHLIGHTS.TAB_NAME]
    },
    {
      key: "3",
      title: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.GAPS.TITLE,
      tabName: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.GAPS.TAB_NAME,
      data: gapsData,
      tooltip:summaryToolTips?.[GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.GAPS.TAB_NAME]
    },
  ];
  return (
    <>
      <SummaryViewText text={GENERIC_CONSTANTS.SUB_TEXTS.TEAM_HEALTH} />
      <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
        {teamHealthColumns.map((item) => (
          <DataSummaryColumn
            key={item.key}
            error={error}
            isLoading={isLoading}
            data={item.data}
            title={item.title}
            tabName={item.tabName}
            tooltip={item.tooltip}
          />
        ))}
      </Row>
    </>
  );
};

export default TeamHealth;