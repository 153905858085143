import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { createFetchReducer  } from '../../utils/reduxUtility';
const initialState = {
  data: [],
  loading: false,
  error: null,
  loaded: false,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    resetViewType: (state, action) => {
      return { ...initialState, data: action.payload};
    },
    setViewType:(state, action) => {
      return { ...initialState, data: action.payload}
    }
  },
});

export const {
  setViewType
} = headerSlice.actions;


export default combineReducers({
  viewType: headerSlice.reducer,
});
