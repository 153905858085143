import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getFilterCategory,
  getFilterCategorySuccess,
  getFilterCategoryFailure,
} from './filterSlice';
import API from './api';

function* fetchFilterCategoryAsync(action) {
  try {
    const response = yield call(API.getFilterCategory, action.payload);
    yield put(getFilterCategorySuccess(response.data));
  } catch (error) {
    yield put(getFilterCategoryFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* filterSaga() {
  yield takeEvery(getFilterCategory.type, fetchFilterCategoryAsync);
}
