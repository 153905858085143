import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import NestedNumberList from "../../components/NestedNumberList";
import PlainNumberedList from "../../components/PlainNumberedList";
import { CardColumn,prepareColumnHeader } from "../../utils/StyledComponents";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import GENERIC_CONSTANTS from "../../Constants/generic";
import "./Styles/TeamMetrics.css";
import { SummaryViewText } from "../../components/SummaryViewText";
import CONSTANTS from "../../utils/constants";


const TeamCapabilities = () => {
  useEffect(() => {}, []);
  const navigate = useNavigate();

  const deepDiveClicked = (tabName) => {
    navigate(`${CONSTANTS.ROUTES.TEAM_CAPABILITIES}/${tabName}`);
  };

  return (
    <>
      <SummaryViewText text="team performance and competency"/>

      <Row style={{display:"flex" ,justifyContent:"space-evenly"}}>
        <ErrorBoundary>
          <CardColumn >
            <div className="inner-box">
              <div className="inner-box-header">
                <div className="card-sticky">
                  {prepareColumnHeader(
                    GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES
                      .TAB_TITILE,
                    GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES
                      .PERFORMANCE_TRENDS.TAB_NAME,
                    deepDiveClicked
                  )}
                </div>

                <div className="card-content card_scroll">
                  <div className="content-title">MEI Score</div>
                  <NestedNumberList />
                </div>
              </div>
            </div>
          </CardColumn>
        </ErrorBoundary>
        <CardColumn >
          <div className="inner-box">
            <div className="inner-box-header">
              <div className="card-sticky">
                {prepareColumnHeader(
                  GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES
                    .TEAM_COMPETENCIES.TITLE,
                  GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES
                    .TEAM_COMPETENCIES.TAB_NAME,
                  deepDiveClicked
                )}
              </div>
            </div>
            <div className="card-content card_scroll">
              <div className="content-title">Top 3 scoring areas</div>
              <div className="plain-list">
                <h4>Summary </h4>
                <PlainNumberedList />
              </div>
              <div className="plain-list">
                <h4>New Joiners </h4>
                <PlainNumberedList />
              </div>
              <div className="plain-list">
                <h4>Summary </h4>
                <PlainNumberedList />
              </div>
            </div>
          </div>
        </CardColumn>
        <CardColumn >
          <div className="inner-box">
            <div className="inner-box-header">
              <div className="card-sticky">
                {prepareColumnHeader(
                  GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES
                    .SELF_COMPETENCIES.TITLE,
                  GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES
                    .SELF_COMPETENCIES.TAB_NAME,
                  deepDiveClicked
                )}{" "}
              </div>
            </div>
            <div className="card-content card_scroll">
              <div className="content-title">Top 3 scoring areas</div>
              <div className="plain-list">
                <h4>Lowest scoring coherts</h4>
                <PlainNumberedList />
              </div>
            </div>
          </div>
        </CardColumn>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCapabilities);
