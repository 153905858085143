import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getStructureDeepdive,
  getStructureDeepdiveSuccess,
  getStructureDeepdiveFailure,
} from './structureSlice';
import API from './api';

function* fetchStructureDeepdiveAsync(action) {
  try {
    const response = yield call(API.getStructureDeepdive, action.payload);
    yield put(getStructureDeepdiveSuccess(response.data));
  } catch (error) {
    yield put(getStructureDeepdiveFailure({status:error.response?.status,message:error.response?.data?.message}));
  }
}

export default function* structureSaga() {
  yield takeEvery(getStructureDeepdive.type, fetchStructureDeepdiveAsync);
}
