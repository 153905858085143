import React from 'react'
import './Styles/ErrorComponent.css'
import GENERIC_CONSTANTS from "../Constants/generic";

export const ErrorComponent = ({error}) => {
  let errorMessage = GENERIC_CONSTANTS.TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
  if(error?.status === 401){
    errorMessage = GENERIC_CONSTANTS.TOAST_MESSAGES.LOGIN_ERROR_MESSAGE;
  }
  else if(error?.status === 400 || error?.status === 404 ||  error?.status === 403){
    errorMessage = error.message
  }
  return (
    <div className={`error_message_container ${error?.status === 401?"error-color-401":"error-color-generic"}`}>
        <p style={{textAlign:"center"}}>{errorMessage} </p>
    </div>
  )
}
