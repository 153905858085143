import React from "react";
import { Table } from "antd";
import styled from "styled-components";
import "../styles/chartStyle.css";
import GENERIC_CONSTANTS from "../../../../Constants/generic";

const StyledTable = styled(Table)`
  .ant-table-thead tr th {
    background-color: rgb(242, 244, 247);
    color: rgb(102, 104, 117);
    font-weight: 500;
    border: none; /* Remove border outline */
  }
  .ant-table tr:nth-child(odd) {
    color: #667085;
    line-height: 20px;
    font-size: 14px;
    background-color: #FCFCFD;
}

  .ant-table tr td {
    color: rgb(102, 104, 117);
    width:100px;
  }

  .ant-table {
    border: 1px solid ${GENERIC_CONSTANTS.COLORS.CARD_BORDER_BOTTOM}; /* Add thick outer border */
    border-bottom-left-radius: 12px 12px;
    border-bottom-right-radius: 12px 12px;
}
`;

const FeederRoles = ({ chartObj }) => {
  const columns = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "No of Employees",
      dataIndex: "noOfEmployees",
      key: "noOfEmployees",
    },
  ];

  let data = [
    {
      source:
        "Key Account Executive, South 1 (KK)[Merchant BU - Pod]",
      destination: "Executive, B2B PG [B2B PG - Pod]",
      noOfEmployees: "20",
    },
    {
      source:
        "Key Account Executive, East (AP + TS + WB + NESA) [Merchant BU - Pod]",
      destination: "Executive, B2B PG [B2B PG - Pod]",
      noOfEmployees: "5",
    },
    {
      source: "Key Account Executive, West 2 (MH + Goa) [Merchant BU - Pod]",
      destination: "Executive, B2B PG [B2B PG - Pod]",
      noOfEmployees: "6",
    },
    {
      source: "Advisor, Insurance [BESI BU - Pod] ",
      destination: "Advisor, Reopen [Consumer Platforms and Payments BU - Pod",
      noOfEmployees: "4",
    },
  ];

  // Add a new row for "Total"
  data.push({
    source: (
      <span style={{ color: "rgb(47, 54, 67)",fontWeight:"600" }}>Total</span>
    ),
    destination: "",
    noOfEmployees: (
      <span style={{ color: "rgb(47, 54, 67)",fontWeight:"600"  }}>310</span>
    ), // Assuming "350" as the total number of employees
  });

  const getTitle = () => {
    return (
      <div>
        <div className="tableTitle">{chartObj.title}</div>
        <div className="tablesubTitle">{chartObj.subtitle}</div>
      </div>
    );
  };

  return (
    <>
      <StyledTable
        bordered={false}
        className="structure_table"
        
        dataSource={data}
        columns={columns.map((col) => ({
          ...col,
          title: () => <div className="structure_table_head">{col.title}</div>,
        }))}
        pagination={false}
        title={() => getTitle()}
      />

    </>
  );
};

export default FeederRoles;
