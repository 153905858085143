import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import TeamHealth from "./TeamHealth";
import TeamMetrics from "./TeamMetrics";
import TeamCapabilities from "./TeamCapabilities";
import GENERIC_CONSTANTS from "../../Constants/generic";
import {CustomTab} from "../../utils/StyledComponents";
import './Styles/TeamMetrics.css'
import { getReporteeHealthSummary } from "../../redux/teamHealth/healthSlice";
import { getReporteeMetricsSummary } from "../../redux/teamMetrics/metricsSlice";
import { setDataSummaryTabKey } from "../../redux/topFilter/topFilterSlice";
import ActionPlan from "../Actions/ActionTab";
import { Tooltip } from 'antd';


const DataSummary = ({ viewType }) => {

  const [displayMetrics, setDisplayMetrics] = useState(false);
  const dataSummaryTabKey = useSelector(
    (state) => state.topFilter.topFilter.dataSummaryTabKey
  );
  const isLoading = useSelector(
    (state) => state.topFilter.topFilter.loading);
  const { topFilterPayload } = useSelector(state => state.topFilter.topFilter);
  const dispatch  = useDispatch();
  const onChange = (key) => {
    switch(key){

      case '1': dispatch(getReporteeHealthSummary(topFilterPayload));
                break;
      case '2': dispatch(getReporteeMetricsSummary(topFilterPayload));
                break;
    }
    dispatch(setDataSummaryTabKey(key));
  };

  useEffect(() => {
    setDisplayMetrics(
      viewType === GENERIC_CONSTANTS.LABELS.DATA ? true : false
    );
  }, [viewType]);

  const items = [
    {
      key: "2",
      label: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_METRICS.TAB_TITILE,
      children: <TeamMetrics />,
      disabled:isLoading
    },
    {
      key: "1",
      label: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_HEALTH.TAB_TITILE,
      children: <TeamHealth />,
      disabled:isLoading
    },
    
    // {
    //   key: "3",
    //   label: GENERIC_CONSTANTS.DATA_SUMMARY_TABS.TEAM_CAPABILITIES.TAB_TITILE,
    //   children: <TeamCapabilities />,
    //   disabled:isLoading
    // },
  ];

  return (
    <div style={{ marginTop: "0px", padding: "0px" }}>
      {displayMetrics ? (
        <div>
          <Tooltip title = {GENERIC_CONSTANTS.TOOLTIPS.DATA_SUMMARY} color="#F9F5FF">
          <h2
            style={{
              margin: "0px",
              marginLeft: "20px",
              opacity: isLoading ? 0.5 : 1,
              width:"fit-content"
            }}
          >Data Summary</h2>
          </Tooltip>
          <CustomTab defaultActiveKey= {dataSummaryTabKey} items={items} onChange={onChange} />
        </div>
      ) : (
        <div>
          <h2
            style={{
              margin: "0px",
              marginLeft: "20px",
              opacity: isLoading ? 0.5 : 1,
            }}
          >
            Action Plan
          </h2>
          <ActionPlan/>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  viewType: state.header.viewType.data || "",
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataSummary);
