import TREND_UP from '../assets/images/icons/trend-up.svg';
import TREND_DOWN from '../assets/images/icons/trend-down.svg';
import BACK_ICON from '../assets/images/icons/arrow-narrow-left.svg'
import ELLIPSE from '../assets/images/icons/Ellipse.svg'
import ARROW_UP from '../assets/images/icons/arrow-up.svg'
import ARROW_DOWN from '../assets/images/icons/arrow-down.svg'
import ARROW_SAME_LEVEL from '../assets/images/icons/arrow-samelevel.svg'
import DOTS_VERTICAL from '../assets/images/icons/dots-vertical.svg'
import PENCIL from '../assets/images/icons/pencil.svg'
import MESSAGE_SQUARE from '../assets/images/icons/message-square.svg'
import CHECK_SQUARE from '../assets/images/icons/check-square.svg'
import TRASH from '../assets/images/icons/trash.svg'
import SEND_ICON from '../assets/images/icons/send-icon.svg'
import FILTER_ICON from '../assets/images/icons/filter-icon.svg'
import DOWNLOAD_ICON from '../assets/images/icons/download-icon.svg'
import DOT_GREEN from '../assets/images/icons/Dot.svg'
export const IMAGES = {
    IMAGES: {},
    ICONS: {
        TREND_UP: TREND_UP,
        TREND_DOWN: TREND_DOWN,
        BACK_ICON: BACK_ICON,
        ELLIPSE: ELLIPSE,
        ARROW_UP: ARROW_UP,
        ARROW_DOWN: ARROW_DOWN,
        DOTS_VERTICAL:DOTS_VERTICAL,
        PENCIL:PENCIL,
        MESSAGE_SQUARE:MESSAGE_SQUARE,
        CHECK_SQUARE:CHECK_SQUARE,
        TRASH:TRASH,
        SEND_ICON:SEND_ICON,
        FILTER_ICON:FILTER_ICON,
        DOWNLOAD_ICON:DOWNLOAD_ICON,
        ARROW_SAME_LEVEL:ARROW_SAME_LEVEL,
        DOT_GREEN:DOT_GREEN
    },
    ALT_TEXT:{
        TREND_UP: "TREND_UP",
        TREND_DOWN: "TREND_DOWN",
        BACK_ICON: "BACK_ICON",
        ELLIPSE: "ELLIPSE",
        ARROW_UP: "ARROW_UP",
        ARROW_DOWN: "ARROW_DOWN",
        DOTS_VERTICAL:"DOTS_VERTICAL",
        PENCIL:"PENCIL",
        MESSAGE_SQUARE:"MESSAGE_SQUARE",
        CHECK_SQUARE:"CHECK_SQUARE",
        TRASH:"TRASH",
        SEND_ICON:"SEND_ICON",
        FILTER_ICON:"FILTER_ICON",
        DOWNLOAD_ICON:"DOWNLOAD_ICON",
        ARROW_SAME_LEVEL:"ARROW_SAME_LEVEL",
        DOT_GREEN:"DOT_GREEN"
    }
}