const ApiPaths = {
  
  getMetricsSummary: '/getMetricsSummary',
  getHealthSummary: '/getHealthSummary',
  getReporteeMetricsSummary:'/getReporteeMetricsSummary',
  getReporteeHealthSummary:'/getReporteeHealthSummary',
  getDeepDiveTrend:'/getDeepDiveTrend',
  getDeepDiveAttrition:'/getDeepDiveAttrition',
  getDeepDiveHiring:'/getDeepDiveHiring',
  getDeepDiveStructure:'/getDeepDiveStructure',
  getTopFilter:'/getTopFilter',
  getManagerReporteeList:'/getManagerReporteeList',
  getHrbpReporteeList:'/getHrbpReporteeList',
  getActionsList: '/getActionsList',
  getActionPlansList:'/getActionPlansList',
  createAction: '/createAction',
  editAction:'/editAction',
  deleteAction:'/deleteAction',
  markComplete:'/markComplete',
  getRemraksList:'/getActionRemarksList',
  addActionRemarks:'/addActionRemarks',
  getFilterCategory:'getFilterCategory',
  
}
export default ApiPaths;